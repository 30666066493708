

import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'

export const Help = () => {
  return (
    <div>
      <div className='contininin'>
      <div class="containerrrrr">
                <div class="row justify-content-center">
                    
                    <div class="col-lg-4 col-md-6">
                    <Link to="/servicesmain">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center">
                                    <p>Products</p>
                                </div>
                                <div class="card-back">
                                    <p>Products</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    
                   
                    <div class="col-lg-4 col-md-6">
                    <Link to="/Itmanagement">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center " style={{textAlign:"center"}}>
                                    <p>IT Management</p>
                                </div>
                                <div class="card-back">
                                    <p>IT Management</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                   
                    <div class="col-lg-4 col-md-6">
                        <Link to="/MachineLearning">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center">
                                    <p>Machine Learning</p>
                                </div>
                                <div class="card-back">
                                    <p>Machine Learning</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6">
                          <Link to="/DataServices">                     
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center">
                                    <p>Data Servcies</p>
                                </div>
                                <div class="card-back">
                                    <p>Data Servcies</p>
                                </div>
                            </div>
                        </div>
                        </Link>   
                    </div>
                    <div class="col-lg-4 col-md-6">
                          <Link to="/DigitalMarketing">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center">
                                    <p>Digital Marketing</p>
                                </div>
                                <div class="card-back">
                                    <p>Digital Marketing</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-md-6">
                          <Link to="/servicessubpage5">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center">
                                    <p>Web Services</p>
                                </div>
                                <div class="card-back">
                                    <p>Web Services</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
      </div>
    </div>
  )
}
