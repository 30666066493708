import React,{useEffect,useState} from 'react';
import './home.css';
import  Img9 from '../img/image (1).jpg';
import  Img10 from '../img/image (2).jpg';
import  Img11 from '../img/image (3).jpg';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import { Clientsay } from './clientsay';



export const Services = () => {
    useEffect(() => {
        const counters = document.querySelectorAll('.counter');
        counters.forEach((counter) => {
          const countTo = parseInt(counter.dataset.count);
          const duration = 3000;
          const increment = Math.ceil(countTo / duration * 100);
    
          let count = 0;
          const updateCount = () => {
            count += increment;
            if (count < countTo) {
              counter.innerText = count;
              setTimeout(updateCount, 85);
            } else {
              counter.innerText = countTo;
            }
          };
    
          updateCount();
        });
      }, []);

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');

    const [name, setname] = useState('');
    const [phone, setphone] = useState('');
    const [email, setemail] = useState('');
    const [subject, setsubject] = useState('');
    const [Message, setMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
 
    const handlename = (e) => {
        setname(e.target.value);
      };
 
      const handleemail = (e) => {
        setemail(e.target.value);
        setSeverity('success');
      };
 
      const handlesubject = (e) => {
        setsubject(e.target.value);
      };
 
      const handlephone = (e) => {
        setphone(e.target.value);
      };
 
      const handleMessage = (e) => {
        setMessage(e.target.value);
      };
 
      const handleSubmit = (e) => {
        e.preventDefault();
     
        if (!name || !phone || !email || !subject || !Message ) {
          return;
        }
     
        const emailRegex = /^[a-zA-Z0-9._%+-]+@(?:gmail\.com|yahoo\.com)$/i;
        if (!emailRegex.test(email)) {
            setMessage('Please enter a valid email address.');
            setSeverity('error');
            setOpen(true);
          return;
        }
     
        if (phone.length < 10) {
            setMessage('Please enter a valid phone number.');
            setSeverity('error');
            setOpen(true);
         
          return;
        }
     
        const data = {
          name: name,
          phone: phone,
          email: email,
          subject: subject,
          message: Message
        };
     
        sendMessage(data)
          .then(() => {
            setFormSubmitted(true);
            setname('');
            setphone('');
            setemail('');
            setsubject('');
            setMessage('');
          })
          .catch((error) => {
            console.error("Error sending message:", error);
          });
      };
      
    const sendMessage = (data) => {
        const telegram_bot_id = "6493636406:AAHtY5NEz7XfyXAQ0Ex2CAZpJWWEiPiiwtQ";
        const chat_id = 1968186632;
        const message = `Name: ${data.name}\nPhone Number: ${data.phone}\nEmail: ${data.email}\nSubject: ${data.subject}\nMessage: ${data.message}`;
   
        const settings = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'cache-control': 'no-cache',
          },
          body: JSON.stringify({
            chat_id: chat_id,
            text: message,
          }),
        };
   
        return new Promise((resolve, reject) => {
          fetch(`https://api.telegram.org/bot${telegram_bot_id}/sendMessage`, settings)
            .then((response) => {
            //   console.log(response);
              resolve();
            })
            .catch((error) => {
                setMessage('Incorrect response.');
                setSeverity('error'); // Change severity to error for incorrect response
                setOpen(true);
              console.error(error);
              reject(error);
            });
        });
      };

      useEffect(() => {
        if (formSubmitted) {
            setMessage('Your request has been sent.');
            setOpen(true);
            setFormSubmitted(false);
        }
      }, [formSubmitted]);
      
      const handleClose = () => {
        setOpen(false);
    };
    
  return (
    <div>
        <div id='services'>
         <div>
    <div className="count-up">
        <div className="wrapper">
            <i class="material-symbols-outlined">social_leaderboard</i>
            <div className='d-flex'>
            <div className="counter" data-count="24">0</div>
            <div><h5>+</h5></div>
            </div>
            <p1>Awards Won</p1>
          </div>
          <div className="wrapper">
          <i class="material-symbols-outlined">psychology</i>
            <div className='d-flex'>
            <div className="counter" data-count="12">0</div>
            <div><h5>+</h5></div>
            </div>
            <p1>Years Experience</p1>
          </div>
          <div className="wrapper">
          <i class="material-symbols-outlined">thumb_up</i>
            <div className='d-flex'>
            <div className="counter" data-count="352">0</div>
            <div><h5>+</h5></div>
            </div>
            <p1>Happy Clients</p1>
          </div>
        </div>
    </div>
     <div>
     <div>
            <div className="contact" id="contact">
                <div className="container">
                    <div className="heading text-center">
                        <h2>
                            Contact
                            <span> Us </span>
                        </h2>
                        <p>
                            Thank you for considering Yata Yati for your learning and placement needs. We are here to assist you in every possible way.
                            <br /> Please feel free to reach out to us through the
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="title">
                                <h3>Contact detail</h3>
                                <p>
                                    Whether you have questions, feedback, or you're interested in our services, feel free to reach out to us. Your satisfaction is our priority.
                                </p>
                            </div>
                            <div className="content">
                                <div className="info">
                                    <i className="material-symbols-outlined">call</i>
                                    <h4 className="d-inline-block">
                                        PHONE :
                                        <br />
                                        <span>+917799955921 , +917799955924</span>
                                    </h4>
                                </div>

                                <div className="info">
                                    <i className="material-symbols-outlined">mail</i>
                                    <h4 className="d-inline-block">
                                        EMAIL :
                                        <br />
                                        <span>info@Yatayati.com</span>
                                    </h4>
                                </div>

                                <div className="info">
                                    <i className="material-symbols-outlined">home</i>
                                    <h4 className="d-inline-block">
                                        ADDRESS :
                                        <br />
                                        <span>OLd Alwal,Secunderabad,Telangana,500010 ,<br/>Yatayati info solution  private limited</span>
                                    </h4>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 ">
                          <form>
                              <div className="row pt-5">
                                  <div className="col-sm-6">
                                      <input type="text" className="form-control" placeholder="Name" name="name"  onChange={handlename} required />
                                  </div>
                                  <div className="col-sm-6">
                                      <input type="email" className="form-control" placeholder="Email" name="email"  onChange={handleemail} required />
                                  </div>
                                  <div className="col-sm-7">
                                      <input type="tel" className="form-control" placeholder="Phone Number" name="phone_number"  onChange={handlephone} required />
                                  </div>
                                  <div className="col-sm-12">
                                      <input type="text" className="form-control" placeholder="Subject" name="subject"  onChange={handlesubject} required />
                                  </div>
                                  <div className="form-group">
                                      <textarea className="form-control" rows="5" id="comment" placeholder="Message" name="message"  onChange={handleMessage} required multiline />
                                  </div>
                              </div>
                          </form>
                      </div>
                      <div className='buttononon'><button className="buttonon" type="button" onClick={handleSubmit}>Send Now!</button></div>

                    </div>
                </div>
            </div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={severity === 'error' ? 'error' : 'success'}>
                    {Message}
                </Alert>
            </Snackbar>

        </div>

     </div>
     <div>
      <Clientsay/>
      </div>
    </div>
    </div>
  )
}

   
   

    