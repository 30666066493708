import React from 'react';
import './home.css';
import  Img9 from '../img/image (1).jpg';
import  Img10 from '../img/image (2).jpg';
import  Img11 from '../img/image (3).jpg';

export const Clientsay = () => {
  return (
    <div>
         <div>
       <div className='sectionmkjk'>
       <div class="containerrr">
            <h1 class="section-header mt-5">Client <span>Review</span></h1>
            <div class="testimonials">         
                <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="single-item">
                          <div class="row">
                              <div class="col-md-5">
                                  <div class="profile">
                                      <div class="img-area">
                                          <img src= {Img9} alt=""/>
                                      </div>
                                      <div class="bio">
                                          <h2>Sheela</h2>
                                          <h4>Aplihs Software Solutions</h4>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <div class="content">
                                      <p className='p1'><span><i class="fa fa-quote-left"></i></span>From the moment I reached out to them, they were quick to respond, addressing my inquiries with professionalism and efficiency.Throughout the project, they demonstrated a remarkable level of dedication, ensuring that deadlines were met and that the work was of the highest quality. Their team's ability to communicate effectively and adapt to any changes or challenges that arose was commendable</p>
                                      <p class="socials d-flex flex-row justify-content-center">
                                          <i class="fa fa-twitter"></i>
                                          <i class="fa fa-behance"></i>
                                          <i class="fa fa-pinterest"></i>
                                          <i class="fa fa-dribbble"></i>
                                          <i class="fa fa-vimeo"></i>
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="single-item">
                          <div class="row">
                              <div class="col-md-5">
                                  <div class="profile">
                                      <div class="img-area">
                                          <img src={Img10} alt=""/>
                                      </div>
                                      <div class="bio">
                                          <h2>Taher</h2>
                                          <h4>Welcare organisation</h4>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <div class="content">
                                      <p className='p1'><span><i class="fa fa-quote-left"></i></span>They listened attentively to our needs and vision for the website, providing valuable insights and suggestions along the way.
 
 Not only did they deliver a visually stunning and user-friendly website, but they also went above and beyond to ensure that it effectively communicated our message and showcased our work. Their attention to detail and willingness to accommodate our requests made the entire process seamless and enjoyable.</p>
                                      <p class="socials d-flex flex-row justify-content-center">
                                          <i class="fa fa-twitter"></i>
                                          <i class="fa fa-behance"></i>
                                          <i class="fa fa-pinterest"></i>
                                          <i class="fa fa-dribbble"></i>
                                          <i class="fa fa-vimeo"></i>
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="single-item">
                          <div class="row">
                              <div class="col-md-5">
                                  <div class="profile">
                                      <div class="img-area">
                                          <img src={Img11} alt=""/>
                                      </div>
                                      <div class="bio">
                                      <h2>Pronusha</h2>
                                          <h4>Sln nuero</h4>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <div class="content">
                                      <p className='p1'><span><i class="fa fa-quote-left"></i></span>The process began with thorough consultations where they took the time to understand our requirements and goals for the website. Throughout the development phase, they remained highly responsive, promptly addressing any questions or concerns that arose.What truly impressed me was their attention to detail and commitment to delivering a user-friendly and visually appealing website.</p>
                                      <p class="socials d-flex flex-row justify-content-center">
                                          <i class="fa fa-twitter"></i>
                                          <i class="fa fa-behance"></i>
                                          <i class="fa fa-pinterest"></i>
                                          <i class="fa fa-dribbble"></i>
                                          <i class="fa fa-vimeo"></i>
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
                
            </div>
        </div>
       </div>
        </div>
    </div>
  )
}
