import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'
import yat from "../img/yat.jpg";
import empdash from "../img/empdash.png";
import emppdash from "../img/emppdash.png";
import emp from "../img/emp.png";
import empleaves from "../img/empleaves.png";
import empreset from "../img/empreset.png";
import empfile from "../img/empfile.png";
import { Footer } from '../components/footer';
import attemptt from "../img/attemptt.png";
import atmp from "../img/atmp.png";
import attmp from "../img/attmp.png";
import atttmp from "../img/atttmp.png";
import attemp from "../img/attemp.png";
import attempt from "../img/attempt.png";
import webinars from "../img/webinars.png";
import couses from "../img/couses.png";
import ions from "../img/ions.png";
import ques from "../img/ques.png";
import upd from "../img/upd.png";
import updd from "../img/updd.png";
import upddd from "../img/upddd.png";
import systemm from "../img/systemm.png";
import systemmm from "../img/systemmm.png";
import system from "../img/system.png";
import budget from "../img/budget.png";
import budddget from "../img/budddget.png";
import buddget from "../img/buddget.png";
import activee from "../img/activee.png";
import active from "../img/active.png";
import pay from "../img/pay.png";
import chit from "../img/chit.png";
import admin from "../img/admin.png";
import payout from "../img/payout.png";
import { IoArrowBack } from 'react-icons/io5';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import { Navbarr } from '../components/navbarr';

export const Servicessubpage1 = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};
  return (
    <div>
        <div>
      <section class="section_all bg-light" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section_title_all text-center">
                            <h3 class="font-weight-bold"> Assessment <span class="text-custom">Management</span></h3>
                            <div class="">
                                <i class=""></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold"> Student Dash </p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Student Dashboard Overview</h4>
                            <p class="text-muted mt-3">A student dashboard streamlines access to critical academic resources, fostering efficiency and organization in student workflows. By centralizing information and tools, it empowers students to manage tasks and stay informed about their academic progress. </p>

                            <p class="text-muted mt-3"> This user-friendly interface encourages proactive engagement and effective time management within educational settings.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={attmp} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={atmp} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Student Manangement</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Student Management Practices</h4>
                            <p class="text-muted mt-3">Student management practices requires collaboration among
                  educators, administrators, and support staff. Utilizing
                  technology, such as student  and communication
                  platforms, can streamline these processes. </p>

                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    
                </div>
                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Dashboard</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Dashboard Communication Features</h4>
                            <p class="text-muted mt-3">This dashboard provide a space for internal communications,
                      announcements, and personalized notifications. </p>

                            <p class="text-muted mt-3"> </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={attemp} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={attempt} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Attempt</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Attempt Evaluation and Feedback</h4>
                            <p class="text-muted mt-3">It plays a crucial role in evaluating individual
                                            performance, providing feedback, and contributing to the
                                            overall assessment
                          </p>

                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    
                </div>
                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Attempt file</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Managing Attempt Files</h4>
                            <p class="text-muted mt-3">It plays a crucial role in evaluating individual
                  performance, providing feedback, and contributing 
                  results,test name and start date to the overall assessment
 </p>

                            <p class="text-muted mt-3"> </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={attemptt} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={atttmp} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Admin Page</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Admin Page Functionality</h4>
                            <p class="text-muted mt-3">An admin page, short for administration page, typically
                  refers to a web-based interface or dashboard designed for
                  administrators or authorized personnel to manage  and
                  oversee various aspects of a system, application, or website.
                          </p>

                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
      </div>
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
                <IoArrowBack style={{ transform: 'rotate(90deg)' }} />
         </button>
        <Footer/>
     
    </div>
  )
}
