import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'
import { Footer } from '../components/footer';
import { IoArrowBack } from 'react-icons/io5';
import { TextField, Button, Snackbar, Alert } from '@mui/material';

import Imgg1 from '../img/sss1 (4).png'
import Imgg2 from '../img/sss1 (1).png'
import Imgg3 from '../img/sss1 (3).png'
import Imgg4 from '../img/sss1 (2).png'

export const Servicessubpage = () => {
const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
  return (
    <div>
      <div>
      <section class="section_all bg-light" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section_title_all text-center">
                            <h3 class="font-weight-bold">Employee <span class="text-custom">Management</span></h3>
                            <div class="">
                                <i class=""></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Dashboard</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Streamlined Insights and Analytics</h4>
                            <p class="text-muted mt-3">Gain comprehensive insights into your organization's performance with our intuitive dashboard. Visualize key metrics, track employee productivity, and monitor KPIs seamlessly. </p>

                            <p class="text-muted mt-3"> Our dashboard provides real-time data to facilitate informed decision-making, ensuring operational efficiency and strategic growth.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={Imgg1} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={Imgg2} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">My Pay</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Effortless Payroll Management</h4>
                            <p class="text-muted mt-3">Simplify payroll processes with our My Pay feature. From accurate salary calculations to automated payment schedules, manage employee compensation effortlessly. </p>

                            <p class="text-muted mt-3">Our secure platform ensures compliance with tax regulations and offers employees transparency into their earnings, promoting trust and satisfaction.</p>
                        </div>
                    </div>
                    
                </div>
                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Profile</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Personalized Employee Profiles</h4>
                            <p class="text-muted mt-3">Empower employees with personalized profiles that showcase their professional journey and achievements. Our Profile feature enables seamless self-management of personal information, skills, and career milestones. </p>

                            <p class="text-muted mt-3"> Foster engagement and career development by encouraging employees to maintain up-to-date profiles and showcase their unique contributions to the organization.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={Imgg3} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={Imgg4} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Reporting Team</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Collaborative Team Reporting</h4>
                            <p class="text-muted mt-3">Enhance team collaboration and performance tracking with our Reporting Team tool. Generate custom reports, monitor project progress, and allocate resources efficiently. </p>

                            <p class="text-muted mt-3">Empower managers with actionable insights to optimize workflows and foster a culture of accountability within your organization.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
      </div>
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
                <IoArrowBack style={{ transform: 'rotate(90deg)' }} />
         </button>
        <Footer/>
    </div>
  )
}
