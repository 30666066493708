import React, { useState } from 'react';
import './navbar.css';
import {HashLink as Link} from 'react-router-hash-link';
 
export const Navbar = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
 
  const handleHover = (index) => {
    setHoveredIndex(index);
  };
 
  const handleLeave = () => {
    setHoveredIndex(null);
  };
  
  return (
    <div className="navbar-container  d-md-block">
      <Link to="/"><p
        onMouseEnter={() => handleHover(0)}
        onMouseLeave={handleLeave}
        className={hoveredIndex === 0 ? 'active' : ''}
      >
       Home
      </p>
      <hr className={hoveredIndex === 0 ? 'active' : ''} /></Link>
      <Link to="/web">
      <p
        onMouseEnter={() => handleHover(1)}
        onMouseLeave={handleLeave}
        className={hoveredIndex === 1 ? 'active' : ''}
      >
        About Us
      </p>
      <hr className={hoveredIndex === 1 ? 'active' : ''} /></Link>
      <Link to="/Servicesmain">
      <p
        onMouseEnter={() => handleHover(2)}
        onMouseLeave={handleLeave}
        className={hoveredIndex === 2 ? 'active' : ''}
      >
        Services
      </p>
      <hr className={hoveredIndex === 2 ? 'active' : ''} /></Link>
      <Link to="/contact">
      <p
        onMouseEnter={() => handleHover(3)}
        onMouseLeave={handleLeave}
        className={hoveredIndex === 3 ? 'active' : ''}
      >
        Contact Us
      </p>
      <hr className={hoveredIndex === 3 ? 'active' : ''} /></Link>
    </div>
  );
};
 
