import React from 'react';
import {Navbarr} from '../components/navbarr';
 
const Layoutt = ({ children }) => (
<div>
<Navbarr/>
    {children}
</div>
);
 
export default Layoutt;