import React from "react";
import { Image } from "react-bootstrap"; 
import Imggg1 from "../img/ISB Product Management Programme_ Unlock Your Potential in Product Management.jpg";
import Imggg2 from "../img/infrastructure.png";
import Imggg3 from "../img/database.png";
import Imggg4 from "../img/sucession.png";
import Imggg5 from "../img/cloud-computing.png";
import Imggg6 from "../img/help-desk.png";
import Imggg7 from "../img/it programmer.png";
import Footer from "../components/footer";
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';

export const Itmanagement = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className="it-management-page">
        <div className="itup">
          <h1 className="itcenter">IT Management</h1>
          <div className="sectioononn">
            <h2></h2>
          </div>

          <div>
            <div class="row justify-content-around">
              <div class="sectioononn col-md-6 d-flex flex-column justify-content-center">
                <h2>Introduction to IT Management</h2>
                <p>

- Yatayati IT Management excels in optimizing organizational tech infrastructure.
<br></br>
- Specializing in network, server, security and compliance, we provide tailored expertise for your IT needs.
                </p>
              </div>
              <div class="image-gallery col-md-6">
                <img src={Imggg1} alt="IT Management" />
              </div>
            </div>
          </div>

          <div className="sectioononnn">
            <h1 style={{ textAlign: "center" }}>
              Why Choose Our IT Management Solutions?
            </h1>
            <div>
            <div class="row justify-content-center sdfsfsf ">
              <div class=" col-lg-4 col-md-6 mb-4 ">
                <div class="card custom-card">
                  <div class="card-body">
                    <h3 class="card-title">Enhanced Efficiency</h3>
                    <p class="card-text">
                      IT management ensures that technology aligns with business
                      goals, optimizing processes and workflows.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4">
                <div class="card custom-card">
                  <div class="card-body">
                    <h3 class="card-title">Improved Security</h3>
                    <p class="card-text">
                      Proper IT management includes robust cybersecurity
                      measures to protect against threats and breaches.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4" >
                <div class="card custom-card">
                  <div class="card-body">
                    <h3 class="card-title">Cost Optimization</h3>
                    <p class="card-text">
                      Strategic IT management helps in allocating resources
                      efficiently, reducing unnecessary expenditures.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div>
            <h2 class="text-center" style={{fontFamily:"anta"}}>Key Services Offered</h2>
            <div className="d-flex flex-row justify-content-center" style={{fontFamily:"anta"}}>
            <div >
            <div class="row sdfsfsf">
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color1 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg2}
                      alt="Network infrastructure management"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                      Network infrastructure management
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color2 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg3}
                      alt="Server and database management"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">Server and database management</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color3 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg4}
                      alt="Security and compliance solutions"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                      Security and compliance solutions
                    </p>
                  </div>
                </div>
              </div>
              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color4 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg5}
                      alt="Cloud computing and virtualization"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                      Cloud computing and virtualization
                    </p>
                  </div>
                </div>
              </div>

              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color5 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg6}
                      alt="IT support and helpdesk services"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                      IT support 
                    </p>
                  </div>
                </div>
              </div>
              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color6 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg7}
                      alt="IT consulting"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">helpdesk services</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
          </div>
     <br/>
          <div>
        <h2 class="text-center mb-4" style={{fontFamily:"anta"}}>Why Choose Yatayati for IT Management?</h2>
        <div class="why-choose-yatayati-section d-flex justify-content-between row">
            <div className="col-lg-4 col-md-6 mb-4">
            <div class="value-proposition yt-section">
                <h3>Value Proposition</h3>
                <p>Yatayati IT Management offers tailored solutions to meet your specific IT needs, maximizing efficiency and reliability.</p>
            </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
            <div class="team-expertise yt-section ">
                <h3>Team Expertise</h3>
                <p>Our team of IT professionals and consultants bring years of experience and expertise to every project, ensuring successful outcomes.</p>
            </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
            <div class="cost-effective-solutions yt-section ">
                <h3>Cost-Effective Solutions</h3>
                <p>We are committed to delivering cost-effective solutions that maximize ROI for our clients, helping them achieve their IT goals within budget.</p>
            </div>
            </div>
        </div>
    </div>
        <div>
        <h1 style={{textAlign:"center", fontFamily:"anta"}}>Client Success Stories</h1>

        <div class="outerdiv">

            <div class="innerdiv">

            <div class="div1 eachdiv">
                <div class="userdetails">
                <div class="imgbox">
                    <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-daniel.jpg" alt=""/>
                </div>
                <div class="detbox">
                <p class="name">John Doe</p>
                <p class="designation">IT Manager</p>
                  </div>
                </div>
                <div class="review">
                    <h4>Yatayati's IT Management solutions exceeded our expectations.</h4>
                    <p>“ Yatayati's expertise and proactive approach have transformed our IT infrastructure, making it more resilient and adaptable to our evolving needs. Their tailored solutions have exceeded our expectations. Thanks to Yatayati, our IT infrastructure has become more robust and efficient. Their tailored approach and proactive support have significantly enhanced our operations. ”</p>
                </div>
            </div>
            
            <div class="div2 eachdiv">
                <div class="userdetails">
                <div class="imgbox">
                    <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jonathan.jpg" alt=""/>
                </div>
                <div class="detbox">
            <p class="name">Jane Smith</p>
            <p class="designation">IT Consultant</p>
        </div>
    </div>
    <div class="review">
        <h4>Yatayati's team provided invaluable insights and support.</h4>
        <p>“ Working with Yatayati has been a game-changer for our IT department. Their expertise and dedication have helped us overcome challenges and achieve our goals. ”</p>
    </div>
            </div>
            
            <div class="div3 eachdiv">
                <div class="userdetails">
                <div class="imgbox">
                    <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-kira.jpg" alt=""/>
                </div>
                <div class="detbox">
            <p class="name dark">Michael Johnson</p>
            <p class="designation dark">IT Director</p>
        </div>
    </div>
    <div class="review dark">
        <h4>Yatayati's IT Management solutions are second to none.</h4>
        <p>“ We've seen remarkable improvements in our IT operations since partnering with Yatayati. Their customized solutions and proactive approach have been instrumental in our success.Partnering with Yatayati has been one of the best decisions for our IT department. Their tailored solutions have not only resolved our existing challenges but also prepared us for future growth and scalability.Yatayati's attention to detail and dedication to understanding our business needs have made a significant impact on our IT infrastructure. ”</p>
    </div>
            </div>
            <div class="div4 eachdiv">
                <div class="userdetails">
                <div class="imgbox">
                    <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jeanette.jpg" alt=""/>
                </div>
                <div class="detbox">
            <p class="name dark">Emily Brown</p>
            <p class="designation dark">IT Specialist</p>
        </div>
    </div>
    <div class="review dark">
        <h4>Yatayati's IT Management services have exceeded our expectations.</h4>
        <p>“ Yatayati's team has been instrumental in streamlining our IT processes and enhancing our security measures.  ”</p>
    </div>
            </div>
            <div class="div5 eachdiv">
                <div class="userdetails">
                <div class="imgbox">
                    <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-patrick.jpg" alt=""/>
                </div>
                <div class="detbox">
            <p class="name">Sarah Wilson</p>
            <p class="designation">IT Administrator</p>
        </div>
    </div>
    <div class="review">
        <h4>Yatayati's IT Management solutions have been invaluable.</h4>
        <p>“ Yatayati's expertise and support have helped us optimize our IT infrastructure and improve our overall efficiency. Their tailored solutions have addressed our specific needs effectively. We couldn't be happier with the results. Yatayati's commitment to delivering top-notch IT management services is unmatched. Their ability to understand our unique challenges and provide effective solutions sets them apart from the rest. ”</p>
    </div>
            </div>
            </div>
        </div>
        </div>
        <div style={{textAlign:"center"}}>
          <div className="row p-5" style={{fontFamily:"anta"}}>
                <div className="ctsts d-flex flex-column justify-content-center col-md-6">
                    <h1>"Have questions or feedback? Reach out to us. We're here to help! "</h1>
                </div>
                <div className="col-md-6 ">
                    <Link to="/contact">
                        <button className="cta" onClick={handleScrollToTop}>
                            <span style={{fontFamily:"anta"}}>Contact Us</span>
                            <svg width="15px" height="10px" viewBox="0 0 13 10">
                                <path d="M1,5 L11,5"></path>
                                <polyline points="8 1 12 5 8 9"></polyline>
                            </svg>
                        </button>
                    </Link>
                </div>
            </div>
          </div>
        </div>
      </div>
      <button className="scroll-to-top-button" onClick={handleScrollToTop}>
        <IoArrowBack style={{ transform: "rotate(90deg)" }} />
      </button>
      <Footer />
    </div>
  );
};
