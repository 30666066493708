import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'
import yat from "../img/yat.jpg";
import empdash from "../img/empdash.png";
import emppdash from "../img/emppdash.png";
import emp from "../img/emp.png";
import empleaves from "../img/empleaves.png";
import empreset from "../img/empreset.png";
import empfile from "../img/empfile.png";
import { Footer } from '../components/footer';
import attemptt from "../img/attemptt.png";
import atmp from "../img/atmp.png";
import attmp from "../img/attmp.png";
import atttmp from "../img/atttmp.png";
import attemp from "../img/attemp.png";
import attempt from "../img/attempt.png";
import webinars from "../img/webinars.png";
import couses from "../img/couses.png";
import ions from "../img/ions.png";
import ques from "../img/ques.png";
import upd from "../img/upd.png";
import updd from "../img/updd.png";
import upddd from "../img/upddd.png";
import systemm from "../img/systemm.png";
import systemmm from "../img/systemmm.png";
import system from "../img/system.png";
import budget from "../img/budget.png";
import budddget from "../img/budddget.png";
import buddget from "../img/buddget.png";
import activee from "../img/activee.png";
import active from "../img/active.png";
import pay from "../img/pay.png";
import chit from "../img/chit.png";
import admin from "../img/admin.png";
import payout from "../img/payout.png";
import { IoArrowBack } from 'react-icons/io5';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import { Navbarr } from '../components/navbarr';

export const Servicessubpage4 = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};
  return (
    <div>
         <div>
      <section class="section_all bg-light" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section_title_all text-center">
                            <h3 class="font-weight-bold"> Chit  <span class="text-custom">Funds</span></h3>
                            <div class="">
                                <i class=""></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold"> Chit Funds </p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Chit Funds Overview</h4>
                            <p class="text-muted mt-3">In a chit fund, member entry involves recording details about the   
              participants(members) who are contributing to and benefiting from 
              the chit fund scheme.
</p>

                            <p class="text-muted mt-3"> </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={pay} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={payout} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Payout</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Payout view  page</h4>
                            <p class="text-muted mt-3">In a chit fund, a payout refers to the distribution of funds among the  
                members based on the predetermined schedule or as per the terms of the 
                chit fund agreement.</p>
                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    
                </div>
                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Chit Group</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Chit Group Page</h4>
                            <p class="text-muted mt-3">In a chit fund, a payout refers to the distribution of funds among the  
              members based on the predetermined schedule or as per the terms of the 
              chit fund agreement.
 </p>

                            <p class="text-muted mt-3"> </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={chit} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={admin} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Admin Dashboard</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Admin Dashboard view  page</h4>
                            <p class="text-muted mt-3">Creating an admin dashboard for a chit fund involves displaying key  
                information and functionalities that administrators need to manage 
                the chit fund effectively</p>
                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </section>
      </div>
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
                <IoArrowBack style={{ transform: 'rotate(90deg)' }} />
         </button>
        <Footer/>
    </div>
  )
}
