import React,{useEffect} from 'react';
import './home.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import anime from 'animejs';
import  video1  from '../video/video6.mp4';

// import  Img1 from '../img/cloud.png';
// import  Img2 from '../img/dashboard.png';
// import  Img3 from '../img/hybrid.png';
// import  Img4 from '../img/illustration.png';
// import  Img5 from '../img/machine-learning.png';
// import  Img6 from '../img/monitor.png';

import './help.jsx';
import { Link } from 'react-router-dom';


export const Home = () => { 

    // const [isCardClicked, setIsCardClicked] = useState(false);
    // const handleClick = () => {
    //   setIsCardClicked(!isCardClicked);
    // };

   

  useEffect (() =>{
    Aos.init({duration: 2000,
      })
  },[])
   
  useEffect(() => {
    const textWrapper = document.querySelector('.ml6 .letters');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    anime.timeline({ loop:true})
      .add({
        targets: '.ml6 .letter',
        translateY: ["1.1em", 0],
        translateZ: 0,
        duration:750,
        delay: (el, i) => 50 * i
      }).add({
        targets: '.ml6',
        opacity: 0,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000
      });
  }, []);

  return (
    <div>
    
      <div id='home'>
      <div>
      <div className='home-video' id='box1'>
    <video src={ video1 } autoPlay loop muted></video>
    <div className='main-heading'>
    <h1>YATA YATI</h1>
    </div>
    <div className='main-sub-heading'>
      <p>Empower your bussiness</p>
       <h1 className="ml6">
        <span className="text-wrapper">
          <h2 className="letters">Excellent IT services for your success</h2>
        </span>
      </h1>
        <Link to ="/contact">
        <button class="c-button c-button--gooey"> MEET WITH US
              <div class="c-button__blobs">
              <div></div>
              <div></div>
              <div></div>
              </div>
            </button>
        </Link>
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{display:'block', height: '0', width: '0'}}>
                <defs>
                  <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo"></feColorMatrix>
                    <feBlend in="SourceGraphic" in2="goo"></feBlend>
                  </filter>
                </defs>
              </svg>
          </div>
        </div>
      </div>
    </div>
</div>
  )
}
