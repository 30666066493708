import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'
import yat from "../img/yat.jpg";
import empdash from "../img/empdash.png";
import emppdash from "../img/emppdash.png";
import emp from "../img/emp.png";
import empleaves from "../img/empleaves.png";
import empreset from "../img/empreset.png";
import empfile from "../img/empfile.png";
import { Footer } from '../components/footer';
import attemptt from "../img/attemptt.png";
import atmp from "../img/atmp.png";
import attmp from "../img/attmp.png";
import atttmp from "../img/atttmp.png";
import attemp from "../img/attemp.png";
import attempt from "../img/attempt.png";
import webinars from "../img/webinars.png";
import couses from "../img/couses.png";
import ions from "../img/ions.png";
import ques from "../img/ques.png";
import upd from "../img/upd.png";
import updd from "../img/updd.png";
import upddd from "../img/upddd.png";
import systemm from "../img/systemm.png";
import systemmm from "../img/systemmm.png";
import system from "../img/system.png";
import budget from "../img/budget.png";
import budddget from "../img/budddget.png";
import buddget from "../img/buddget.png";
import activee from "../img/activee.png";
import active from "../img/active.png";
import pay from "../img/pay.png";
import chit from "../img/chit.png";
import admin from "../img/admin.png";
import payout from "../img/payout.png";
import { IoArrowBack } from 'react-icons/io5';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import { Navbarr } from '../components/navbarr';

export const Servicessubpage6 = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};
  return (
    <div>
         <div>
            <div className="rkkkk d-flex flex-row justify-content-between">
          <div>
              <Link to="/">
              <img src={yat} className="yat"/>
              </Link>
          </div>
          <Navbarr/>
          <div className=" d-flex flex-column justify-content-center">
                <a href={`tel:+917799955921`} className=' callbutton d-flex flex-row justify-content-evenly '>
                    <p className="wokkkk"><i className="material-symbols-outlined">call</i> </p>
                    <p className='callcolror'>Call Us Now!</p>
                </a>
            </div>
        </div>
            </div>
     <nav className='d-flex flex-row justify-content-center '>
        <div class="nav nav-tabs " id="nav-tab" role="tablist">
            <button class="nav-link  d-none" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Employee Management</button>
            <button class="nav-link  d-none" id="nav-home2-tab" data-bs-toggle="tab" data-bs-target="#nav-home2" type="button" role="tab" aria-controls="nav-home2" aria-selected="false">Assessment Management</button>
            <button class="nav-link  d-none" id="nav-home3-tab" data-bs-toggle="tab" data-bs-target="#nav-home3" type="button" role="tab" aria-controls="nav-home3" aria-selected="false">Learning Management</button>
            <button class="nav-link  d-none" id="nav-home4-tab" data-bs-toggle="tab" data-bs-target="#nav-home4" type="button" role="tab" aria-controls="nav-home4" aria-selected="false">Student Management</button>
            <button class="nav-link  d-none" id="nav-home5-tab" data-bs-toggle="tab" data-bs-target="#nav-home5" type="button" role="tab" aria-controls="nav-home5" aria-selected="false">Chit Funds</button>
            <button class="nav-link  d-none" id="nav-home6-tab" data-bs-toggle="tab" data-bs-target="#nav-home6" type="button" role="tab" aria-controls="nav-home6" aria-selected="false">Web Development</button>
            <button class="nav-link active d-none" id="nav-home7-tab" data-bs-toggle="tab" data-bs-target="#nav-home7" type="button" role="tab" aria-controls="nav-home7" aria-selected="false">Function Hall Management</button>
        </div>
        </nav>
          <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
          <div className="mp">
        <div>
        </div>
        <h1 className="empppp d-flex flex-row justify-content-center"> Employee Management</h1>
        <div className="poe">
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={empdash} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Dashboard </p>
              <p className="work">
                This dashboard provides a space for internal<br></br>{" "}
                communications, announcements, and <br></br>personalized
                notifications.
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="ton ">
              <p className="emp1">Dashpage</p>
              <div className="work">
                <p>
                  The dashpage feature makes sure if key performance indicators
                  (KPIs) relevant to the employee's role and responsibilities.
                  Managing the admission process, including application reviews
                  and
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={emppdash} alt="" className="pic1" />
            </div>
          </div>
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={emp} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Employee Details </p>
              <p className="work">
                The employee management system gives access to the <br></br>
                admin/Hr to add the details of the employee to ensure<br></br>the
                secured data.
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="tonn">
              <p className="emp1">Leaves</p>
              <div className="work">
                <p>
                The application also has a feature of leaves which is added so
                that there is zero confusion.
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={empleaves} alt="" className="pic11" />
            </div>
          </div>
          
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={empreset} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Reset Page </p>
              <p className="work">
              This reset feature in the employee system page<br></br> provides
                the most convenient<br></br> to reset the employee details.
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="tonn ">
              <p className="emp1">Upload Files</p>
              <div className="work">
                <p>
                Files play a crucial role, this system comes into play for its
                  best security.
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={empfile} alt="" className="pic11" />
            </div>
          </div>
        </div>
        <br/>
          </div>
          </div>
          <div class="tab-pane fade " id="nav-home2" role="tabpanel" aria-labelledby="nav-home2-tab" tabindex="0">
          <div className="mp">
          <h1 className="empppp d-flex flex-row justify-content-center "> Assessment Management</h1>
          <div className="poe">
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={attmp} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" > Student Dash </p>
              <p className="work">
                      A student dashboard involves creating a user interface that<br/>
                      provides students with easy access to relevant information and
                      tools
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="ton ">
              <p className="emp1">Student Manangement</p>
              <div className="work">
                <p>
                  Student management practices requires collaboration among
                  educators, administrators, and support staff. Utilizing<br/>
                  technology, such as student  and communication
                  platforms, can streamline these processes.
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={atmp} alt="" className="pic1" />
            </div>
          </div>

          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={attemp} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Dashboard </p>
              <p className="work">
                      This dashboard provide a space for internal communications,<br/>
                      announcements, and personalized notifications.
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="tonn">
              <p className="emp1">Attempt</p>
              <div className="work">
                <p>
                It plays a crucial role in evaluating<br></br> individual
                  performance, providing feedback,<br></br> and contributing to the
                  overall assessment
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={attempt} alt="" className="pic11" />
            </div>
          </div>
          
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={attemptt} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Attempt file </p>
              <p className="work">
                It plays a crucial role in evaluating individual<br></br>{" "}
                  performance, providing feedback, and contributing <br></br>
                  results,test name and start date to the overall assessment
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="tonn ">
              <p className="emp1">Admin Page </p>
              <div className="work">
                <p>
                  An admin page, short for administration page, typically<br></br>{" "}
                  refers to a web-based interface or dashboard designed <br></br>for
                  administrators or authorized personnel to manage <br></br>and
                  oversee various aspects of a system, application, or website.
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={atttmp} alt="" className="pic11" />
            </div>
          </div>
        </div>
          </div>
          </div>
          <div class="tab-pane fade " id="nav-home3" role="tabpanel" aria-labelledby="nav-home3-tab" tabindex="0">
          <h1 className="empppp d-flex flex-row justify-content-center "> Learning Management</h1>
          <div className="poe">
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={couses} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Courses</p>
              <p className="work">
                Courses in this dashpage provide information for<br/> Bootstrap, React,
                Html, JavaScript and NodeJs.
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="ton ">
              <p className="emp1">Discussions</p>
              <div className="work">
                <p>
                A discussion on learning management can cover a wide range of<br/>
                topics related to the  Bootstrap, Azure, Reactjs, SQL, Javascript
                of educational experiences.
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={ions} alt="" className="pic1" />
            </div>
          </div>

          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={webinars} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Webinars </p>
              <p className="work">
              Platforms like Zoom, GoToWebinar, and Webex host various webinars<br/>
                on different topics. You can explore their event directories or<br/>
                search for learning management-related webinars.
              </p>
            </div>
          </div>
        </div>
      
          </div>
          <div class="tab-pane fade " id="nav-home4" role="tabpanel" aria-labelledby="nav-home4-tab" tabindex="0">
          <h1 className="empppp d-flex flex-row justify-content-center "> Student Management</h1>
          <div className="poe">
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={activee} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Active dash </p>
              <p className="work">
                An active dashboard in student management refers to a dynamic <br/>
                  and interactive user interface that provides real-time<br/>
                  information and functionalities related to student data and
                  management.
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="ton ">
              <p className="emp1">Active Courses</p>
              <div className="work">
                <p>
                      These platforms offer a variety of courses, and you can<br/>
                      search for currently active courses based on your interests
                      or field of study like sql,html.and training for sql .
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={active} alt="" className="pic1" />
            </div>
          </div>

          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={ques} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Questions </p>
              <p className="work">
              This platform helps you to find the answers for question we<br/>
                  need based on courses.
              </p>
            </div>
          </div>
          <h1 className="empppp d-flex flex-row justify-content-center "> Student view</h1>

          <div className="minn d-flex flex-row" >
            <div className="tonn">
              <p className="emp1">Student Management</p>
              <div className="workk">
                <p>
                  Can customize it further based on the specific features and<br/>
                  functionalities you want to offer to administrators, such as<br/>
                  managing students, courses, grades, and other administrative
                  tasks.
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={budget} alt="" className="pic11" />
            </div>
          </div>
          
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={buddget} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Student Management </p>
              <p className="work">
                    If you are designing a student management system from the<br/>
                    perspective of a student view, it's important to create a<br/>
                    user-friendly interface that provides easy access to relevant
                    information.
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="tonn ">
              <p className="emp1">Student Management</p>
              <div className="workk">
                <p>
                  An admission form is a document used by educational<br/>
                  institutions, organizations, or programs to collect essential<br/>
                  information from individuals seeking admission. The form serves <br/>
                  as a structured way to gather details about the applicant's<br/>
                  personal, educational, and sometimes professional background.<br/>
                  Here's a breakdown of the typical sections found in an admission<br/>
                  form:
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={budddget} alt="" className="pic111" />
            </div>
          </div>
          <h1 className="empppp d-flex flex-row justify-content-center "> Teacher view</h1>
          <div className="minn d-flex flex-row" >
            <div className="tonn">
              <p className="emp1">Student Management system</p>
              <div className="work">
                <p>
                This page manages salary record and No of employee Assesed for particular month.
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={system} alt="" className="pic11" />
            </div>
          </div>
          
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={systemm} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Student Management </p>
              <p className="work">
                  To create an attendance report, you would typically need to<br/>
                    gather attendance data and present it in a structured format.
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="tonn ">
              <p className="emp1">Student Management</p>
              <div className="work">
                <p>
                  To create a student-wise or a class-wise result page,<br/> you would
                  typically need to gather and display student-specific academic
                  results and proceed select .
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={systemmm} alt="" className="pic11" />
            </div>
          </div>
          <h1 className="empppp d-flex flex-row justify-content-center "> Admin view</h1>
          <div className="minn d-flex flex-row" >
            <div className="tonn">
              <p className="emp1">Dashboard</p>
              <div className="work">
                <p>
                The Admin Dashboard contains total number of students,<br/>
                  employees,revenue and profits of employees.
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={upddd} alt="" className="pic11" />
            </div>
          </div>
          
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={updd} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Student Dashboard </p>
              <p className="work">
              To create an attendance report, you would typically need to<br/>
                    gather attendance data and present it in a structured format.
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="tonn ">
              <p className="emp1">Student Management</p>
              <div className="work">
                <p>
                  This page contains information on employee which requies<br/>
                  institute name ,website,phone number adress and country to
                  update to next format.
                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={upd} alt="" className="pic11" />
            </div>
          </div>
        </div>

          </div>
          <div class="tab-pane fade " id="nav-home5" role="tabpanel" aria-labelledby="nav-home5-tab" tabindex="0">
          <h1 className="empppp d-flex flex-row justify-content-center "> Chit Funds</h1>
          <div className="poe">
          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={pay} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Chit Funds</p>
              <p className="work">
              In a chit fund, member entry involves recording details about the  <br/>
              participants(members) who are contributing to and benefiting from 
              the chit fund scheme.
              </p>
            </div>
          </div>

          <div className="minn d-flex flex-row" >
            <div className="ton ">
              <p className="emp1">Payout</p>
              <div className="work">
                <p>
                In a chit fund, a payout refers to the distribution of funds among the <br/>
                members based on the predetermined schedule or as per the terms of the 
                chit fund agreement.

                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={payout} alt="" className="pic1" />
            </div>
          </div>

          <div className="tet d-flex flex-row">
            <div className="tanu">
              <img src={chit} alt="" className="pic" />
            </div>
            <div className="textt d-flex flex-column justify-content-center">
              <p className="emp" >Chit Group </p>
              <p className="work">
              In a chit fund, a payout refers to the distribution of funds among the <br/>
              members based on the predetermined schedule or as per the terms of the 
              chit fund agreement.
              </p>
            </div>
          </div>
          <div className="minn d-flex flex-row" >
            <div className="ton ">
              <p className="emp1">Admin Dashboard</p>
              <div className="work">
                <p>
                Creating an admin dashboard for a chit fund involves displaying key <br/>
                information and functionalities that administrators need to manage 
                the chit fund effectively.


                </p>
              </div>
            </div>
            <div className="tanu ">
              <img src={admin} alt="" className="pic1" />
            </div>
          </div>
        </div>
          </div>
          <div class="tab-pane fade " id="nav-home6" role="tabpanel" aria-labelledby="nav-home6-tab" tabindex="0">
          <h1 className="empppp d-flex flex-row justify-content-center "> Web Development </h1>
          <div class="container">
                  <div class="row justify-content-center">
                      
                      <div class="col-lg-4 col-md-6">
                      <Link to="https://interiordesignyatayati.netlify.app/" target="_blank">
                          <div class="card">
                              <div class="card-inner">
                                  <div class="card-front d-flex flex-column justify-content-center">
                                      <p>Interior Designing</p>
                                  </div>
                                  <div class="card-back">
                                      <p>Technology stack<br/>React, Node.js & MySQL..</p>
                                  </div>
                              </div>
                          </div>
                          </Link>
                      </div>
                      
                    
                      <div class="col-lg-4 col-md-6">
                      <Link to="https://yatayatiportfoliotemplate5.netlify.app/"target="_blank">
                          <div class="card">
                              <div class="card-inner">
                                  <div class="card-front d-flex flex-column justify-content-center " style={{textAlign:"center"}}>
                                      <p>Technology stack</p>
                                  </div>
                                  <div class="card-back">
                                      <p>Technology stack<br/>WPF(.Net Framework) c# and mySQL.</p>
                                  </div>
                              </div>
                          </div>
                          </Link>
                      </div>
                    
                      <div class="col-lg-4 col-md-6">
                          <Link to="https://yatayatiportfoliotemplate3.netlify.app/" target="_blank">
                          <div class="card">
                              <div class="card-inner">
                                  <div class="card-front d-flex flex-column justify-content-center">
                                      <p>Portfolio</p>
                                  </div>
                                  <div class="card-back">
                                      <p>Technology stack<br/> React, Node.js & MySQL.</p>
                                  </div>
                              </div>
                          </div>
                          </Link>
                      </div>
                  </div>
                  <div class="row justify-content-center">
                      <div class="col-lg-4 col-md-6">
                            <Link to="https://yatayatiportfoliotemplate2.netlify.app/" target="_blank">                     
                          <div class="card">
                              <div class="card-inner">
                                  <div class="card-front d-flex flex-column justify-content-center">
                                      <p>Mentor Portfolio </p>
                                  </div>
                                  <div class="card-back">
                                      <p>Technology stack<br/> React, Node.js & MySQL.</p>
                                  </div>
                              </div>
                          </div>
                          </Link>   
                      </div>
                      <div class="col-lg-4 col-md-6">
                            <Link to="https://yatayatiportfoliotemplate1.netlify.app/" target="_blank">
                          <div class="card">
                              <div class="card-inner">
                                  <div class="card-front d-flex flex-column justify-content-center">
                                      <p>Desginer Portfolio </p>
                                  </div>
                                  <div class="card-back">
                                      <p>Technology stack<br/> React, Node.js & MySQL.</p>
                                  </div>
                              </div>
                          </div>
                          </Link>
                      </div>
                      <div class="col-lg-4 col-md-6">
                            <Link to="https://e-commerceyatayati1.netlify.app/" target="_blank">
                          <div class="card">
                              <div class="card-inner">
                                  <div class="card-front d-flex flex-column justify-content-center">
                                      <p>E-Commerce</p>
                                  </div>
                                  <div class="card-back">
                                      <p>Technology stack<br/> React, Node.js & MySQL.</p>
                                  </div>
                              </div>
                          </div>
                          </Link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tab-pane fade show active" id="nav-home7" role="tabpanel" aria-labelledby="nav-home7-tab" tabindex="0">
          <h1 className="empppp d-flex flex-row justify-content-center "> Function Hall Management </h1>
            
          </div>
        </div>
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
                <IoArrowBack style={{ transform: 'rotate(90deg)' }} />
         </button>
        <Footer/>
    </div>
  )
}
