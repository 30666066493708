import React from "react";
import "./navbarr.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Imghg from  '../img/Imghg.png';

export const Navbarr = () => {
  return (
    <div>
      <div className="headerrrrrr">
        <div className="d-flex">
        <div className="Imghg">
          <img src={Imghg} alt="yatayati"/>
          </div>
            <div>
            <Link to ="/"> <div className="logo">YATA YATI</div></Link>
            </div> 
          </div>      
        <input type="checkbox" id="nav_check" hidden />
        <div className="navvav">
          <ul>
            <li></li>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/web">About us</a>
            </li>
            <li className="menu-item-has-children">
              <a href="/servicesmain">Services <FontAwesomeIcon icon={faAngleDown} /></a>
              <div className="sub-menu">
                <div className="mega-menu">
                  <div className="mega-menu-column ">
                    <ul className="d-flex flex-column">
                      <li></li>
                      <li><a href="/Servicessubpage">Employee Management</a></li>
                      <li><a href="/servicessubpage1">Assessment Management</a></li>
                      <li><a href="/servicessubpage2">Learning Management</a></li>
                    </ul>
                  </div>
                  <div className="mega-menu-column">
                    <ul className="d-flex flex-column">
                      <li></li>
                      <li><a href="/servicessubpage3">Student Management</a></li>
                      <li><a href="/servicessubpage4">Chit Funds</a></li>
                      <li><a href="/servicessubpage5">Web Development</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
        <div>
          <a href={`tel:+917799955921`}>
            {" "}
            <button className="butointini">
              {" "}
              <i className="material-symbols-outlined">call</i> <span className="namensmae">Call us<span className="namensmaee"> now</span></span>
            </button>{" "}
          </a>
        </div>
        <label htmlFor="nav_check" className="hamburger">
          <div></div>
          <div></div>
          <div></div>
        </label>
      </div>
    </div>
  );
};
