import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'
import yat from "../img/yat.jpg";
import empdash from "../img/empdash.png";
import emppdash from "../img/emppdash.png";
import emp from "../img/emp.png";
import empleaves from "../img/empleaves.png";
import empreset from "../img/empreset.png";
import empfile from "../img/empfile.png";
import { Footer } from '../components/footer';
import attemptt from "../img/attemptt.png";
import atmp from "../img/atmp.png";
import attmp from "../img/attmp.png";
import atttmp from "../img/atttmp.png";
import attemp from "../img/attemp.png";
import attempt from "../img/attempt.png";
import webinars from "../img/webinars.png";
import couses from "../img/couses.png";
import ions from "../img/ions.png";
import ques from "../img/ques.png";
import upd from "../img/upd.png";
import updd from "../img/updd.png";
import upddd from "../img/upddd.png";
import systemm from "../img/systemm.png";
import systemmm from "../img/systemmm.png";
import system from "../img/system.png";
import budget from "../img/budget.png";
import budddget from "../img/budddget.png";
import buddget from "../img/buddget.png";
import activee from "../img/activee.png";
import active from "../img/active.png";
import pay from "../img/pay.png";
import chit from "../img/chit.png";
import admin from "../img/admin.png";
import payout from "../img/payout.png";
import { IoArrowBack } from 'react-icons/io5';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import { Navbarr } from '../components/navbarr';

export const Servicessubpage3 = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};
  return (
    <div>
        <div>
      <section class="section_all bg-light" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section_title_all text-center">
                            <h3 class="font-weight-bold"> Student <span class="text-custom">Management</span></h3>
                            <div class="">
                                <i class=""></i>
                            </div>
                        </div>
                    </div>
                </div>

               
                <div class="row vertical_content_manage mt-5">
               
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Student view Management</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4"> student  management view page </h4>
                            <p class="text-muted mt-3">Can customize it further based on the specific features and 
                  functionalities you want to offer to administrators, such as 
                  managing students, courses, grades, and other administrative
                  tasks.
                          </p>

                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={budget} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={buddget} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Student Management </p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">student managemnt desgining page </h4>
                            <p class="text-muted mt-3"> If you are designing a student management system from the 
                    perspective of a student view, it's important to create a 
                    user-friendly interface that provides easy access to relevant
                    information.

                          </p>

                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    
                </div>
                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Student Management</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">student admission management</h4>
                            <p class="text-muted mt-3"> An admission form is a document used by educational 
                  institutions, organizations, or programs to collect essential 
                  information from individuals seeking admission. The form serves  
                  as a structured way to gather details about the applicant's 
                  personal, educational, and sometimes professional background.</p>

                            <p class="text-muted mt-3"> </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={budddget} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={upddd} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Dashboard</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Dashboard view  page</h4>
                            <p class="text-muted mt-3"> The Admin Dashboard contains total number of students, 
                  employees,revenue and profits of employees.

                          </p>

                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    
                </div>
                <div class="row vertical_content_manage mt-5">
                
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Student Dashboard  </p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Student Dashboard view page</h4>
                            <p class="text-muted mt-3"> To create an attendance report, you would typically need to 
                    gather attendance data and present it in a structured format.


                          </p>

                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={updd} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={upd} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Student Information </p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4"> Student Information view page</h4>
                            <p class="text-muted mt-3"> This page contains information on employee which requies 
                  institute name ,website,phone number adress and country to
                  update to next format.</p>
                            <p class="text-muted mt-3"> </p>
                        </div>
                    </div>
                    
                </div>
                
                
                
            </div>
        </section>
      </div>
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
                <IoArrowBack style={{ transform: 'rotate(90deg)' }} />
         </button>
        <Footer/>
    </div>
  )
}
