import React from "react";
import "./Web.css";
import { Link } from "react-router-dom";

import visi from "../img/visi.png";
import cir from "../img/cir.png";
import brain from "../img/brain.png";
import ser from "../img/ser.png";
import serr from "../img/serr.png";
import serrr from "../img/serrr.png";
import yat from "../img/yat.jpg";
import Img1 from "../img/img (2).png";
import { Navbarr } from "../components/navbarr";
import Footer from "../components/footer";
import { IoArrowBack } from "react-icons/io5";

const Web = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

  return (
    <div>
      <div>
            <Navbarr/>
            <br/>
            <br/>
            <div className="mamasm">
            <div className="bri">
              <img src={visi} alt=""/>
              <p>Let's bring <br/>your vision <br/>to <span className="life">life</span></p>
            </div> 
          <div>
            <div class="diridir d-flex flex-row justify-content-between align-items-center">
                <div class="diririr  ">
                    {/* <img src={cir} /> */}
                    <div class="dir ">
                        <span class="life">Transforming</span> the landscape of web and app development, we embed ingenious designs and pioneering concepts into each project. Our fervor for creativity fuels bespoke solutions for your digital endeavors, enhanced by our expertise in AI/ML, embedded systems, and IoT.
                    </div>
                </div> 
                <div class="dirr ">
                    <img src={brain} />
                </div> 
            </div>
          </div>
          <div>
          <div className="d-flex flex-row justify-content-center"> 
                
                <p className="teh">
                <span className="who">Who We Are:</span><br/>
                  Yatayati Technologies Solutions is a visionary collective<br></br>
                  crafting specialized apps, web solutions,embedded systems,<br></br>and
                  AI/ML innovations.For four transformative years, we've<br></br>
                  redefined possibilities, eager to continue leveraging our<br></br>
                  expertise to shape a future of remarkable technological<br></br>
                  advancementd!
                </p> 
              </div>
          </div>
          <div>
        <p className="who" style={{textAlign:"center"}}>Products</p>

          <div className="d-flex flex-row justify-content-center">
        <ul className="tehh" style={{textAlign:"center"}}>
          <li>Payroll Management</li>
          <li>Learning Management</li>
          <li> Hospital Management System</li>
          <li>Clinic Management</li>
          <li> Customer Relationship Management</li>
          <li>Student Management</li>
          <li>Assessment Management</li>
          <li> Hospital Management System</li>
        </ul>
      </div> 
          </div>
              <div>
                    <div>
                    <div className="service">
                      <p >Services Offered</p>
                        </div> 
                      <div className="wap d-flex flex-row justify-content-center">
                        <div className="row">
                        <div className=" seeeee col-lg-4 col-md-12">
                          <img src={ser} className="ser"/>
                          <p className="app">
                            Web & App<br></br>Development
                          </p>
                          <p className="trans">
                            "Transforming ideas into intuitive,<br></br>high-performing
                            applications.<br></br>Our expertise merges innovation<br></br>with
                            user-centric design, creating<br></br>standout digital experience."
                          </p>
                        </div>
                        <div className=" col-lg-4 col-md-6">
                        <img src={serr}className="serr"/>
                          <p className="app">Embedded systems</p>
                          <p className="trans">
                            "Where innovation meets<br></br>precision,weaving intelligence
                            <br></br>into the core of devices. Our<br></br>expertise crafts
                            seamless,smart<br></br>solutions for a connected world.<br></br>
                            Powering the heartbeat of<br></br>technology with custom-built
                            <br></br>embedded systems.."
                          </p>
                        </div>
                        <div className="serererer col-lg-4 col-md-6">
                        <img src={serrr}className="serrr"/>
                          <p className="app">Data Science</p>
                          <p className="trans">
                            Unleashing intelligence through<br></br>algorithms that learn and
                            adopt,<br></br>revolutionizing problem-solving.<br></br>Our
                            expertise harnesses the <br></br>power of data to create smarter,
                            <br></br>predictive solutions.<br></br>Transforming industries by
                            <br></br>infusing AI/ML to drive efficiency<br></br>and innovation.
                          </p>
                        </div>
                        </div>
                        </div>
                    </div> 
              </div>
                <div>
                        <div>
                <p className="whooooo">Our Expertise</p>
                <div>
                  <p className="smart">Smart Machines with AI/ML Brains:</p>
                  <p className="built">
                    We built super-smart computers thar can learn from what they see and
                    make clever decisions.<br></br>Made machines that talk and
                    understand just like magic!
                  </p>
                </div>
                <div>
                  <p className="smart">Tailored Embedded Systems:</p>
                  <p className="built">
                    Crafted intricate systems for industries like
                    automotive,healthcare,and factories.<br></br>Created special brains
                    and codes for gadgets that do specialjobs.
                  </p>
                </div>
                <div>
                  <p className="smart">E-Learning Solutions for Code Enthusiasts:</p>
                  <p className="built">
                    Engaging virtual courses tailored for passionate code enthusiasts.
                    <br></br>Interactive lessons designed to elevate your coding skills
                    to wizardry.
                  </p>
                </div>
                <div>
                  <p className="smart">Cutting-Edge loT Magic:</p>
                  <p className="built">
                    We make gadgets that chat with each other and share secrets for
                    better work.<br></br>Created a special playground where gadgets play
                    and learn from each other.
                  </p>
                </div>
                <div>
                  <p className="smart">Digital Superheroes for Governments:</p>
                  <p className="built">
                    We make special tools that help governments do their jobs faster and
                    better.<br></br>Built secret tools to make sure everyone is happy
                    and things get done quickly and safely.
                  </p>
                </div>
                      </div>
                </div>
                <div>
                <div className="m-5">
                      <p className="whoom">Let's Create<br>
                      </br> Together</p>
                      <div className="reach">
                        <p className="wh"> Reach out for a collab! <br/> info@yatayti.com <br/>7799955921</p>
                      </div>
                    </div> 
                </div>
            </div>
      </div>
      <button className="scroll-to-top-button" onClick={handleScrollToTop}>
                <IoArrowBack style={{ transform: 'rotate(90deg)' }} />
         </button>
      <Footer/>
    </div>
  );
};

export default Web;
