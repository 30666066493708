// ErrorPage.js
import { Link } from 'react-router-dom'
import yat from "../img/yat.jpg";
import error from "../img/page-not-found.svg";
import React from 'react';
import './home.css'

const ErrorPage = () => {
  return (
    <div>
      <div>
            <div className="rkkkk ">
          <div>
              <Link to="/">
              <img src={yat} className="yat"/>
              </Link>
          </div>
        </div>
            </div>
            <div className="w3-row d-flex flex-row justify-content-between">
      <div className="kk w3-container w3-half" style={{ padding: '100px 0px 100px 50px' }}>
        <h2 style={{ fontSize: '30px' }}>Sorry!</h2>
        <h1 style={{ fontWeight: 'bold', fontSize: '36px' }}>We can't seem to find the resource you're looking for.</h1>
        <p style={{ fontSize: '16px', opacity: '0.7'}}>Please check that the Web site address is spelled correctly.<br />Or go to our <a  className="namename" href="/">home page</a>, and use the menus to navigate to a specific section.</p>
      </div>
      <div className="kk w3-container w3-half d-none d-lg-block" style={{ padding: '100px' }}>
        <div style={{ position: 'relative', display: 'block', width: '312px', height: '312px', WebkitBorderRadius: '50%', MozBorderRadius: '50%', borderRadius: '50%' }}>
         <img src={error} className="yat" />
        </div>
      </div>
    </div>
    </div>
  );
};

export default ErrorPage;
