import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import Mainpage from "./pages/Mainpage";
import LoadingComponent from "./pages/LoadingComponent";
import Web from "./pages/Web";
import Contact from "./pages/Contact";
import { Servicesmain } from "./pages/servicesmain";
import { Servicessubpage } from "./pages/servicessubpage";
import CookiePolicy from "./pages/CookiePolicy";
import ErrorPage from "./pages/ErrorPage";
import { Servicessubpage1 } from "./pages/servicessubpage1";
import { Servicessubpage2 } from "./pages/servicessubpage2";
import { Servicessubpage3 } from "./pages/servicessubpage3";
import { Servicessubpage5 } from "./pages/servicessubpage5";
import { Servicessubpage6 } from "./pages/servicessubpage6";
import { Servicessubpage4 } from "./pages/servicessubpage4";
import Layout from "./pages/layout";
import Layoutt from "./pages/layoutt";
import { Itmanagement } from "./pages/itmanagement";
import { MachineLearning } from "./pages/MachineLearning";
import { DataServices } from "./pages/DataServices";
import { DigitalMarketing } from "./pages/DigitalMarketing";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [isLoading, setLoading] = useState(true); // Assuming initial loading state is true

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setLoading(false);
    }, 2000); 

    return () => clearTimeout(delayTimeout);
  }, []);
 

  return (
    <BrowserRouter>
      <ScrollToTop />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <Routes>
          <Route path="/" element={<Layout><Mainpage /></Layout>} />
          <Route path="/web" element={<Web />} />
          
          <Route path="/contact" element={<Contact />} />
          <Route path="/Servicesmain" element={<Servicesmain />} />
          <Route path="/servicessubpage" element={<Layoutt><Servicessubpage /></Layoutt>} />
          <Route path="/servicessubpage1" element={<Layoutt><Servicessubpage1 /></Layoutt>} />
          <Route path="/servicessubpage2" element={<Layoutt><Servicessubpage2 /></Layoutt>} />
          <Route path="/servicessubpage3" element={<Layoutt><Servicessubpage3 /></Layoutt>} />
          <Route path="/servicessubpage4" element={<Layoutt><Servicessubpage4/></Layoutt>} />
          <Route path="/servicessubpage5" element={<Layoutt><Servicessubpage5 /></Layoutt>} />
          <Route path="/servicessubpage6" element={<Layoutt><Servicessubpage6 /></Layoutt>} />
          <Route path="/CookiePolicy" element={<CookiePolicy />} />
          <Route path="/Itmanagement" element={<Layoutt><Itmanagement /></Layoutt>} />
          <Route path="/MachineLearning" element={<Layoutt><MachineLearning /></Layoutt>} />
          <Route path="/DataServices" element={<Layoutt><DataServices /></Layoutt>} />
          <Route path="/DigitalMarketing" element={<Layoutt><DigitalMarketing /></Layoutt>} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
