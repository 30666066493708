import React, { useEffect,useState } from 'react';
import './CookieNotice.css'; // Import your CSS styles for CookieNotice here
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

const CookieNotice = () => {
  const [accepted, setAccepted] = useState(false);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (cookieAccepted === 'true') {
      setAccepted(true);
    } else {
      // Display the cookie notice after a delay
      setTimeout(() => {
        setDisplay(true);
      }, 4000); // 4 seconds delay
    }

    // Initialize AOS library
    AOS.init({ once: true });

  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setAccepted(true);
  };

  const handleManagePreferences = () => {
    window.location.href = '/preferences';
  };

  if (accepted || !display) {
    return null; 
  }

  return (
    <div className="cookie-card" data-aos="fade-up">
      <span className="title">🍪 Cookie Notice</span>
      <p className="description">
        We use cookies to ensure that we give you the best experience on our website.{' '}
        <a href="/cookiePolicy">Read cookies policies</a>.
      </p>
      <div className="actions">
        <button className="pref" onClick={handleManagePreferences}>
          Manage your preferences
        </button>
        <button className="accept" onClick={handleAccept}>
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieNotice;
