import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'
import yat from "../img/yat.jpg";
import empdash from "../img/empdash.png";
import emppdash from "../img/emppdash.png";
import emp from "../img/emp.png";
import empleaves from "../img/empleaves.png";
import empreset from "../img/empreset.png";
import empfile from "../img/empfile.png";
import { Footer } from '../components/footer';
import attemptt from "../img/attemptt.png";
import atmp from "../img/atmp.png";
import attmp from "../img/attmp.png";
import atttmp from "../img/atttmp.png";
import attemp from "../img/attemp.png";
import attempt from "../img/attempt.png";
import webinars from "../img/webinars.png";
import couses from "../img/couses.png";
import ions from "../img/ions.png";
import ques from "../img/ques.png";
import upd from "../img/upd.png";
import updd from "../img/updd.png";
import upddd from "../img/upddd.png";
import systemm from "../img/systemm.png";
import systemmm from "../img/systemmm.png";
import system from "../img/system.png";
import budget from "../img/budget.png";
import budddget from "../img/budddget.png";
import buddget from "../img/buddget.png";
import activee from "../img/activee.png";
import active from "../img/active.png";
import pay from "../img/pay.png";
import chit from "../img/chit.png";
import admin from "../img/admin.png";
import payout from "../img/payout.png";
import { IoArrowBack } from 'react-icons/io5';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import { Navbarr } from '../components/navbarr';

export const Servicessubpage2 = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};
  return (
    <div>
         <div>
      <section class="section_all bg-light" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section_title_all text-center">
                            <h3 class="font-weight-bold"> Learning <span class="text-custom">Management</span></h3>
                            <div class="">
                                <i class=""></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold"> Courses </p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Courses Overview</h4>
                            <p class="text-muted mt-3">Courses in this dashpage provide information for  Bootstrap, React, Html, JavaScript and NodeJs.</p>

                            <p class="text-muted mt-3"> </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={couses} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={ions} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Discussions</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Discussions view  page</h4>
                            <p class="text-muted mt-3">A discussion on learning management can cover a wide range of 
                topics related to the  Bootstrap, Azure, Reactjs, SQL, Javascript
                of educational experiences.
 </p>

                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    
                </div>
                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Webinars</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Webinars  Creation Page</h4>
                            <p class="text-muted mt-3">Platforms like Zoom, GoToWebinar, and Webex host various webinars 
                on different topics. You can explore their event directories or 
                search for learning management-related webinars. </p>

                            <p class="text-muted mt-3"> </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={webinars} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={activee} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Active dash </p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4"> Active dash Overview</h4>
                            <p class="text-muted mt-3">An active dashboard in student management refers to a dynamic  
                  and interactive user interface that provides real-time 
                  information and functionalities related to student data and
                  management.
                          </p>

                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    
                </div>
                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Active Courses</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4"> Active Courses Overview</h4>
                            <p class="text-muted mt-3"> These platforms offer a variety of courses, and you can 
                      search for currently active courses based on your interests
                      or field of study like sql,html.and training for sql.</p>.

                            <p class="text-muted mt-3"> </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={active} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                </div>
                <div class="row vertical_content_manage mt-5">
                <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src={ques} alt="" class="img-fluid mx-auto d-block"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">Questions</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4"> Questions view page</h4>
                            <p class="text-muted mt-3">This platform helps you to find the answers for question we 
                  need based on courses.
                          </p>

                            <p class="text-muted mt-3"></p>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </section>
      </div>
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
                <IoArrowBack style={{ transform: 'rotate(90deg)' }} />
         </button>
        <Footer/>
    </div>
  )
}
