import React from 'react';
import { Image } from 'react-bootstrap';
import DataServiceImage from '../img/How Data Intelligence is moving different industries_.jpg';
import Imggg2 from "../img/data-collection.png";
import Imggg3 from "../img/exploratory-analysis.png";
import Imggg4 from "../img/predictive-models.png";
import Imggg5 from "../img/web-analytics.png";
import Imggg6 from "../img/data-analytics.png";
import Imggg7 from "../img/optimization.png";
import Footer from '../components/footer';
import './home.css'; // Import external CSS file for styling
import { IoArrowBack } from 'react-icons/io5';
import { Link } from 'react-router-dom';
export const DataServices = () => {
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <div>
        <div className="it-management-page">
          <div className="itup">
            <h1 className="itcenter">Data Services</h1>
            <div className="sectioononn">
              <h2></h2>
            </div>
  
            <div>
              <div class="row justify-content-around">
                <div class="sectioononn col-md-6 d-flex flex-column justify-content-center">
                  <h2>Introduction to Data Services</h2>
                  <p>
                 - Tailored solutions for comprehensive data management, from integration to analytics.<br/>
- Specializing in data architecture and governance, we ensure the accuracy and security of your data assets.
                  </p>
                </div>
                <div class="image-gallery col-md-6">
                  <img src={DataServiceImage} alt="Data services" />
                </div>
              </div>
            </div>
  
            <div className="sectioononnn" style={{fontFamily:"anta"}}>
              <h1 style={{ textAlign: "center" }}>
                Why Choose Our  Data Services?
              </h1>
              <div>
              <div class="row justify-content-center sdfsfsf ">
                <div class=" col-lg-4 col-md-6 mb-4 ">
                  <div class="card custom-card">
                    <div class="card-body">
                      <h3 class="card-title">Comprehensive Data Services</h3>
                      <p class="card-text">
                      - Section highlighting the unique advantages of Yatayati's data science services.                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                  <div class="card custom-card">
                    <div class="card-body">
                      <h3 class="card-title">Improved Security</h3>
                      <p class="card-text">
                      - Emphasize the expertise of Yatayati's data scientists and analysts in leveraging advanced analytics techniques.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4" >
                  <div class="card custom-card">
                    <div class="card-body">
                      <h3 class="card-title">Cost Optimization</h3>
                      <p class="card-text">
                      - Highlight Yatayati's focus on delivering actionable insights and driving tangible business outcomes for clients.
 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
  
            <div>
              <h2 class="text-center" style={{fontFamily:"anta"}}>Key Services Offered</h2>
              <div className="d-flex flex-row justify-content-center" style={{fontFamily:"anta"}}>
            <div >
            <div class="row sdfsfsf">
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color1 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg2}
                      alt="Data collection and preprocessing"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Data collection and preprocessing
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color2 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg3}
                      alt="Exploratory data analysis (EDA)"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">Exploratory data analysis (EDA)</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color3 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg4}
                      alt="Predictive modeling and machine learning"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Predictive modeling and machine learning
                    </p>
                  </div>
                </div>
              </div>
              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color4 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg5}
                      alt=" Data visualization and storytelling"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Data visualization and storytelling
                    </p>
                  </div>
                </div>
              </div>

              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color5 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg6}
                      alt=" Advanced analytics"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Advanced analytics
                    </p>
                  </div>
                </div>
              </div>
              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color6 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg7}
                      alt="optimization"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">optimization</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
            </div>
       <br/>
            <div>
          <h2 class="text-center mb-4" style={{fontFamily:"anta"}}>Why Choose Yatayati for Data services?</h2>
          <div class="why-choose-yatayati-section d-flex justify-content-between row">
              <div className="col-lg-4 col-md-6 mb-4">
              <div class="value-proposition yt-section">
                  <h3>Value Proposition</h3>
                  <p>- Explanation of the value proposition of partnering with Yatayati for data science needs.</p>
              </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
              <div class="team-expertise yt-section ">
                  <h3>Team Expertise</h3>
                  <p>- Mention the commitment to delivering scalable and customized solutions tailored to each client's industry and objectives.</p>
              </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
              <div class="cost-effective-solutions yt-section ">
                  <h3>Cost-Effective Solutions</h3>
                  <p>- Highlight Yatayati's focus on continuous learning and innovation in the rapidly evolving field of data science.</p>
              </div>
              </div>
          </div>
      </div>
          <div>
              <h1 style={{textAlign:"center", fontFamily:"anta"}}>Client Success Stories</h1>
          <div class="outerdiv">
              
              <div class="innerdiv">
              
              <div class="div1 eachdiv">
                  <div class="userdetails">
                  <div class="imgbox">
                      <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-daniel.jpg" alt=""/>
                  </div>
                  <div class="detbox">
                <p class="name">Daniel Clifford</p>
                <p class="designation">Data Analyst</p>
            </div>
        </div>
        <div class="review">
            <h4>Transformed my career with data services</h4>
            <p>“ I was looking for a career change and stumbled upon this data services bootcamp. The courses were incredibly informative, and I found myself fully engaged. After completing the program, I landed a job as a Data Analyst at a leading tech company. The skills I learned here have been invaluable in my new role. The instructors were knowledgeable, and the curriculum was practical and relevant to industry needs. Now, I work as a Data Analyst at a company I admire, and I owe it all to the skills I developed here.”</p>
        </div>
              </div>
              
              <div class="div2 eachdiv">
                  <div class="userdetails">
                  <div class="imgbox">
                      <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jonathan.jpg" alt=""/>
                  </div>
                  <div class="detbox">
                <p class="name">Jonathan Walters</p>
                <p class="designation">Data Scientist</p>
            </div>
        </div>
        <div class="review">
            <h4>Data services bootcamp: A life-changing experience</h4>
            <p>“ As someone with no prior coding experience, I was unsure if I could succeed in the data services field. ”</p>
        </div>
              </div>
              
              <div class="div3 eachdiv">
                  <div class="userdetails">
                  <div class="imgbox">
                      <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-kira.jpg" alt=""/>
                  </div>
                  <div class="detbox">
                <p class="name dark">Kira Whittle</p>
                <p class="designation dark">Data Engineer</p>
            </div>
        </div>
        <div class="review dark">
            <h4>Highly recommended data services bootcamp!</h4>
            <p>“ Before joining this bootcamp, I was unsure about my career path. However, the comprehensive curriculum and hands-on projects helped me gain confidence in my data engineering skills. I now work in a fulfilling role as a Data Engineer, and I couldn't be happier with my decision to enroll. The instructors were knowledgeable, and the curriculum was practical and relevant to industry needs. Now, I work as a Data Analyst at a company I admire, and I owe it all to the skills I developed here.”</p>
        </div>
              </div>
              <div class="div4 eachdiv">
                  <div class="userdetails">
                  <div class="imgbox">
                      <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jeanette.jpg" alt=""/>
                  </div>
                  <div class="detbox">
                <p class="name dark">Jeanette Harmon</p>
                <p class="designation dark">Data Analyst</p>
            </div>
        </div>
        <div class="review dark">
            <h4>Found my dream job after completing the data services bootcamp</h4>
            <p>“ The experience at this data services bootcamp was truly rewarding.  ”</p>
        </div>
              </div>
              <div class="div5 eachdiv">
                  <div class="userdetails">
                  <div class="imgbox">
                      <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-patrick.jpg" alt=""/>
                  </div>
                  <div class="detbox">
                <p class="name">Patrick Abrams</p>
                <p class="designation">Data Scientist</p>
            </div>
        </div>
        <div class="review">
            <h4>Received excellent teaching support from experienced TAs</h4>
            <p>“ The teaching assistants at this data services bootcamp were incredibly helpful and supportive. Their guidance and insights from their own experiences made learning easy and enjoyable. I am grateful for the genuine concern they showed for my progress throughout the program. ”</p>
        </div>
              </div>
              </div>
          </div>
          </div>
          <div style={{textAlign:"center" }}>
            <div className="row  p-5" style={{fontFamily:"anta"}}>
                  <div className="ctsts d-flex flex-column justify-content-center col-md-6">
                      <h1>"Have questions or feedback? Reach out to us. We're here to help! "</h1>
                  </div>
                  <div className="col-md-6 ">
                  <button class="cta">
                     <Link to="/contact"><span style={{fontFamily:"anta"}}>Contact Us</span></Link>
                      <svg width="15px" height="10px" viewBox="0 0 13 10">
                          <path d="M1,5 L11,5"></path>
                          <polyline points="8 1 12 5 8 9"></polyline>
                      </svg>
                      </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
          <IoArrowBack style={{ transform: "rotate(90deg)" }} />
        </button>
        <Footer />
      </div>
    );
};
