import React from 'react';
import { Image } from 'react-bootstrap';
import MLImage from '../img/Free Vector _ Group of people on business training.jpg'; 
import Imggg2 from "../img/stock-market.png";
import Imggg3 from "../img/segment.png";
import Imggg4 from "../img/detection.png";
import Imggg5 from "../img/natural-language-processing.png";
import Imggg6 from "../img/systeem.png";
import Imggg7 from "../img/customization.png";
import Footer from '../components/footer';
import './home.css'
import { IoArrowBack } from 'react-icons/io5';
import { Link } from 'react-router-dom';

export const MachineLearning = () => {
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <div>
      <div className="it-management-page">
        <div className="itup">
          <h1 className="itcenter">Machine Learning</h1>
          <div className="sectioononn">
            <h2></h2>
          </div>

          <div>
            <div class="row justify-content-around">
              <div class="sectioononn col-md-6 d-flex flex-column justify-content-center">
                <h2>Introduction to Machine Learning</h2>
                <p> - Brief overview of Yatayati Info Solutions' expertise in machine learning solutions, emphasizing the importance of leveraging data for business growth.<br/>

- Highlight the benefits of machine learning in driving insights, automation, and decision-making.</p>
              </div>
              <div class="image-gallery col-md-6">
                <img src={MLImage} alt="Machine Learning" />
              </div>
            </div>
          </div>

          <div className="sectioononnn" style={{fontFamily:"anta"}}>
            <h1 style={{ textAlign: "center" }}>
              Why Choose Our Machine Learning Solutions?
            </h1>
            <div>
            <div class="row justify-content-center sdfsfsf ">
              <div class=" col-lg-4 col-md-6 mb-4 ">
                <div class="card custom-card">
                  <div class="card-body">
                    <h3 class="card-title">Customized Approach</h3>
                    <p class="card-text">
                    - Section highlighting the unique advantages of Yatayati Info Solutions' machine learning services                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4">
                <div class="card custom-card">
                  <div class="card-body">
                    <h3 class="card-title">Meeting Specific Needs and Goals</h3>
                    <p class="card-text">
                    - Emphasize the expertise of Yatayati's data scientists and machine learning engineers in developing cutting-edge solutions.                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4" >
                <div class="card custom-card">
                  <div class="card-body">
                    <h3 class="card-title"> Implementation Excellence</h3>
                    <p class="card-text">
                    - Highlight the track record of successful machine learning implementations and client satisfaction.                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div>
            <h2 class="text-center" style={{fontFamily:"anta"}}>Key Services Offered</h2>
            <div className="d-flex flex-row justify-content-center" style={{fontFamily:"anta"}}>
            <div >
            <div class="row sdfsfsf">
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color1 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg2}
                      alt="Predictive analytics and forecasting"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Predictive analytics and forecasting
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color2 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg3}
                      alt="Customer segmentation and targeting"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">Customer segmentation and targeting</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color3 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg4}
                      alt="Anomaly detection and fraud prevention"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Anomaly detection and fraud prevention
                    </p>
                  </div>
                </div>
              </div>
              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color4 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg5}
                      alt="Natural language processing and sentiment analysis"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Natural language processing and sentiment analysis
                    </p>
                  </div>
                </div>
              </div>

              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color5 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg6}
                      alt="Recommendation systems"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Recommendation systems
                    </p>
                  </div>
                </div>
              </div>
              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color6 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg7}
                      alt="personalization"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">personalization</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
          </div>
     <br/>
          <div>
        <h2 class="text-center mb-4" style={{fontFamily:"anta"}}>Why Choose Yatayati for Machine Learning</h2>
        <div class="why-choose-yatayati-section d-flex justify-content-between row" style={{fontFamily:"anta"}}>
            <div className="col-lg-4 col-md-6 mb-4">
            <div class="value-proposition yt-section">
                <h3>Value Proposition</h3>
                <p>- Explanation of the value proposition of partnering with Yatayati Info Solutions for machine learning needs.</p>
            </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
            <div class="team-expertise yt-section ">
                <h3>Team Expertise</h3>
                <p>- Mention the commitment to delivering scalable and customizable solutions tailored to each client's requirements.</p>
            </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
            <div class="cost-effective-solutions yt-section ">
                <h3>Cost-Effective Solutions</h3>
                <p>- Highlight Yatayati's focus on continuous innovation and staying abreast of the latest developments in machine learning technology.</p>
            </div>
            </div>
        </div>
    </div>
        <div>
            <h1 style={{textAlign:"center", fontFamily:"anta"}}>Client Success Stories</h1>
        <div class="outerdiv">
            
            <div class="innerdiv">
            
            <div class="div1 eachdiv">
                <div class="userdetails">
                <div class="imgbox">
                    <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-daniel.jpg" alt=""/>
                </div>
                <div class="detbox">
                <p class="name">Alice Johnson</p>
                <p class="designation">Machine Learning Engineer</p>
            </div>
        </div>
        <div class="review">
            <h4>Revolutionizing healthcare with machine learning</h4>
            <p>“ Our collaboration with Yatayati in implementing machine learning models for healthcare has been groundbreaking. Their expertise in predictive analytics has enabled us to develop personalized treatment plans and improve patient outcomes significantly. Yatayati's commitment to innovation and their deep understanding of machine learning algorithms make them a valuable partner in the healthcare sector. ”</p>
        </div>
            </div>
            
            <div class="div2 eachdiv">
                <div class="userdetails">
                <div class="imgbox">
                    <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jonathan.jpg" alt=""/>
                </div>
                <div class="detbox">
                <p class="name">Bob Smith</p>
                <p class="designation">AI Researcher</p>
            </div>
        </div>
        <div class="review">
            <h4>Pushing the boundaries of AI with Yatayati</h4>
            <p>“ Yatayati's machine learning solutions have been instrumental in advancing our AI research initiatives. Their team's expertise in developing deep learning models has helped  in natural language processing . </p>
            </div>
            </div>
            
            <div class="div3 eachdiv">
                <div class="userdetails">
                <div class="imgbox">
                    <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-kira.jpg" alt=""/>
                </div>
                <div class="detbox">
                <p class="name dark">Charlie Brown</p>
                <p class="designation dark">Data Scientist</p>
            </div>
        </div>
        <div class="review dark">
            <h4>Optimizing business operations with machine learning</h4>
            <p>“ Yatayati's machine learning solutions have transformed our business operations. By leveraging predictive analytics, we've been able to optimize inventory management, enhance customer segmentation, and streamline marketing campaigns. Yatayati's tailored approach and proactive support have made a significant impact on our bottom line. ”</p>
        </div>
            </div>
            <div class="div4 eachdiv">
                <div class="userdetails">
                <div class="imgbox">
                    <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jeanette.jpg" alt=""/>
                </div>
                <div class="detbox">
                <p class="name dark">David Wilson</p>
                <p class="designation dark">Machine Learning Consultant</p>
            </div>
        </div>
        <div class="review dark">
            <h4>Transforming finance with machine learning</h4>
            <p>“ Yatayati's machine learning expertise has revolutionized our financial analytics. Their predictive models have provided invaluable insights into market trends. ”</p>
        </div>
            </div>
            <div class="div5 eachdiv">
                <div class="userdetails">
                <div class="imgbox">
                    <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-patrick.jpg" alt=""/>
                </div>
                <div class="detbox">
                <p class="name">Eva Martinez</p>
                <p class="designation">Machine Learning Specialist</p>
            </div>
        </div>
        <div class="review">
            <h4>Driving innovation with machine learning</h4>
            <p>“ Yatayati's machine learning solutions have been pivotal in driving innovation across our organization. Their expertise in developing custom algorithms has empowered us to automate processes, uncover new insights, and create innovative products. Yatayati's collaborative approach and dedication to excellence make them a trusted partner in our journey towards technological advancement. ”</p>
        </div>
            </div>
            </div>
        </div>
        </div>
          <div style={{textAlign:"center" }}>
          <div className="row  p-5" style={{fontFamily:"anta"}}>
                <div className="ctsts d-flex flex-column justify-content-center col-md-6">
                    <h1>"Have questions or feedback? Reach out to us. We're here to help! "</h1>
                </div>
                <div className="col-md-6 ">
                <button class="cta">
                   <Link to="/contact"><span style={{fontFamily:"anta"}}>Contact Us</span></Link>
                    <svg width="15px" height="10px" viewBox="0 0 13 10">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                    </svg>
                    </button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <button className="scroll-to-top-button" onClick={handleScrollToTop}>
        <IoArrowBack style={{ transform: "rotate(90deg)" }} />
      </button>
      <Footer />
    </div>
    );
};
