import React from 'react';
import './CookieNotice.css'; // Import your CSS styles for CookiePolicy here

const CookiePolicy = () => {
  return (
    <div className="unique-container">
      <div className="unique-card">
        <h1 className="unique-heading">YATAYATI Info Solutions Private Limited Cookie Policy</h1>
        
        <p>Welcome to YATAYATI Info Solutions Private Limited's Cookie Policy. This policy explains how we use cookies and similar technologies on our website to enhance your browsing experience and provide personalized services. By using our website, you consent to the use of cookies as described in this policy.</p>
        
        <h2 className="unique-subheading">Effective Date</h2>
        <p>This Cookie Policy is effective as of 16/3/2024. Please note that this policy may be updated from time to time.</p>
        
        <h2 className="unique-subheading">What are Cookies and Similar Technologies?</h2>
        <p>Cookies are small text files containing information that are stored on your computer or mobile device when you visit a website. Similar technologies, such as web beacons and tracking pixels, may also be used to collect data.</p>
        
        <h2 className="unique-subheading">How We Use Cookies and Similar Technologies</h2>
        <p>YATAYATI Info Solutions Private Limited may place cookies and similar technologies on your device for the following purposes:</p>
        <ul className="unique-list">
          <li><strong>Essential Cookies</strong>: These cookies are necessary for the operation of our website and enable you to navigate the site and use its features.</li>
          <li><strong>Analytics Cookies</strong>: We use analytics cookies to collect information about how visitors use our website, such as which pages are visited most frequently and how users interact with the site. This helps us analyze and improve the performance of our website.</li>
          <li><strong>Functionality Cookies</strong>: Functionality cookies allow us to remember your preferences and provide enhanced features when you return to our website.</li>
          <li><strong>Advertising and Social Media Cookies</strong>: These cookies are used to deliver personalized advertisements and content based on your interests. They may also be used to track the effectiveness of our advertising campaigns and to provide social media features.</li>
        </ul>
        
        <h2 className="unique-subheading">Managing Your Cookie Preferences</h2>
        <p>You can manage your cookie preferences through our cookie consent manager. If you wish to delete existing cookies or block future cookies from being placed on your device, you can adjust your browser settings accordingly. However, please note that blocking or deleting cookies may impact your user experience on our website.</p>
        
        <h2 className="unique-subheading">Third Party Cookies</h2>
        <p>YATAYATI Info Solutions Private Limited may also use cookies and similar technologies from third party companies for analytics, advertising, and social media purposes. These third parties may collect information about your interactions with our website and other websites.</p>
        
        <h2 className="unique-subheading">Contact Us</h2>
        <p>If you have any questions or concerns about our Cookie Policy or our use of cookies, please contact us at <a href="mailto:info@Yatayati.com" className="unique-link">info@Yatayati.com</a>.</p>
        <p>You can adjust your cookie settings at any time through our cookie consent manager.</p>
        
        <p className="unique-last-updated">This Cookie Policy was last updated on 16/3/2024.</p>
      </div>
    </div>
  );
};

export default CookiePolicy;
