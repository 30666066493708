import React from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import  Img8 from '../img/image (6).png';
import  video2  from '../video/video7.mp4';
import  Img7 from '../img/image.png';

export const Aboutus = () => {
  

  return (
    <div id='about'>
       {/* <div className='home-video1' id='box3'>
         <video src={ video2 } autoPlay loop muted></video>
              <div className='img-view'>
                <img src={ Img7 } alt=''></img>
              </div>
              <div className='sub-heading'>
                  <p>It Support For Business</p>
                  <h3>Preparing for your success trusted IT services.</h3>
                  <p>We’ve been a nearly strategic thought leader for five we are bring unrivaled decades <br/>incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam nostrud</p>
                  <div className='sub-heading-icon'>
                    <span><i class="material-symbols-outlined iconn">check_circle</i>Custom Shortcodes</span>
                    <span><i class="material-symbols-outlined iconn">check_circle</i>Data Analyst</span>
                    </div>
                    <div className='sub-heading-icon1'>
                    <span><i class="material-symbols-outlined iconn">check_circle</i>IT Consultancy</span>
                    <span><i class="material-symbols-outlined iconn">check_circle</i>Data Security</span>
                    </div>
              </div>
      </div> */}
      <div className='bodyduyuhd'>
      <div className='section'>
          <div className="image-container">
              <img src={ Img7 } alt=''></img>
          </div>
          <div class="content-container">
              <h2 class="section-title">Preparing for your success trusted IT services</h2>
              <span class="section-description"> </span>
              <p class="section-content">"Our comprehensive services, from software development to infrastructure solutions, are customized to propel your business forward in the digital age."</p>
              <ul class="navigation-links">
                  <li><a href="#">work</a></li>
                  <div class="divider"></div>
                  <li><a href="#">service</a></li>
                  <div class="divider"></div>
                  <li><Link to="/contact">Contact</Link> </li> 
              </ul>
              <ul class="social-icons">
                  <li>
                      <i class="icon-twitter"></i>
                  </li>
                  <li>
                      <i class="icon-facebook"></i>
                  </li>
                  <li>
                      <i class="icon-github"></i>
                  </li>
                  <li>
                      <i class="icon-pinterest"></i>
                  </li>
              </ul>
          </div>
    </div>
      </div>

        <div>
        <div class="responsive-container-block bigContainer">
            <div class="responsive-container-block Container bottomContainer">
              <div class="ultimateImg">
                <img class="mainImg" src={ Img8 } alt=''/>
                <div class="purpleBox">
                  <p class="purpleText">
                  Just Like A wow wow wow 
                  </p>
                  <img class="stars" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/mp5.svg" alt=''/>
                </div>
              </div>
              <div class="allText bottomText">
               <div>
               <p class="text-blk headingText ">
                  About <span className='headingText1' >Me</span>
                </p>
                </div>
                <div>
                <p class="text-blk subHeadingText">
                Preparing for your success trusted source in IT services.
                </p>
                </div>
                <div>
                <p class="text-blk description">
                      Which means as the company ramps<br/>
                      Available in 14.1-in. and 16-in. versions.<br/>
                      The potential loss of the Touch Bar.<br/>
                      High-speed USB 4.0.  <br/>
                </p>
               </div>
                <a href="/Servicesmain" class="explore">View Services</a>
              </div>
            </div>
          </div>
    </div>
    </div>
  )
}
