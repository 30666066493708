import React, { useState } from 'react';
import axios from 'axios';
import './footer.css';

export const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [open, setOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation using regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(?:gmail\.com|yahoo\.com)$/i;

    if (!emailRegex.test(email)) {
      setMessage('Please enter a valid email address ending with @gmail.com or @yahoo.com');
      setSeverity('error'); // Change severity to error for invalid email
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 2000); 
      return;
    }

    try {
      const response = await axios.post('http://localhost:4002/subscribe', { email });
      console.log(response.data);
      setMessage('You are Subscribed :)');
      setSeverity('success'); // Change severity to success for successful response
      setOpen(true);
      setEmail('');
      setTimeout(() => {
        setOpen(false);
      }, 3000);  // Clear the email field after successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessage('There was an error submitting your request. Please try again later.');
      setSeverity('error'); // Change severity to error for incorrect response
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000); 
    }
  };

  return (
    <div>
      <footer>
        <div className="row primary">
          <div className="column about">
            <div className='tayat'>
              <h8 className="yatayati">YATA YATI</h8>
              <p className='yatayati'>
                Yatayati Info Solutions' mission is to enhance the business operation of its clients by developing and/or implementing, premium IT products and services
              </p>
            </div>
          </div>
          <div className="column links">
            <h8 className='yatayati'>Quick Links</h8>
            <ul>
              <li className='yatayati'><a href="#faq">F.A.Q</a></li>
              <li className='yatayati'><a href="#cookies-policy">Cookies Policy</a></li>
              <li className='yatayati'><a href="#terms-of-services">Terms Of Service</a></li>
              <li className='yatayati'><a href="#support">Support</a></li>
              <li className='yatayati'><a href="#careers">Careers</a></li>
            </ul>
          </div>
          <div className="column subscribe">
            <h8 className='yatayati'>Subscribe</h8>
            <form onSubmit={handleSubmit} >
              <input type="email" placeholder="Your email id here" className='mail' value={email} onChange={(e) => setEmail(e.target.value)} />
              <div className='yatayati'>
              <button type="submit" className='button1'>Subscribe</button>
              </div>  
          </form>
            <div className="social">
              <i className="fa-brands fa-facebook-f"></i>
              <i className="fa-brands fa-instagram"></i>
              <i className="fa-brands fa-twitter"></i>
            </div>
          </div>
        </div>
       <div className='d-flex flex-column justify-content-center align-content-center'>
        <div className="row copyright">
          <p>Copyright &copy; 2024 YataYati | All Rights Reserved</p>
        </div>
        </div>
      </footer>

      {open && (
        <div className={`alert alert-${severity === 'error' ? 'danger' : 'success'} right-corner`}>
          {message}
        </div>
      )}
    </div>
  );
};

export default Footer;
