import React from 'react';
import { Image } from 'react-bootstrap';
import DigitalMarketingImage from '../img/An Entrepreneur’s Guide on Outsourcing Digital Transformation.jpg';
import Imggg2 from "../img/seo.png";
import Imggg3 from "../img/ads.png";
import Imggg4 from "../img/content-strategy.png";
import Imggg5 from "../img/email-marketing.png";
import Imggg6 from "../img/writer.png";
import Imggg7 from "../img/analytics.png";
import Footer from '../components/footer';
import './home.css'; // Import external CSS file for styling
import { IoArrowBack } from 'react-icons/io5';
import { Link } from 'react-router-dom';

export const DigitalMarketing = () => {
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <div>
        <div className="it-management-page">
          <div className="itup">
            <h1 className="itcenter">Digital Marketing</h1>
            <div className="sectioononn">
              <h2></h2>
            </div>
  
            <div>
              <div class="row justify-content-around">
                <div class="sectioononn col-md-6 d-flex flex-column justify-content-center">
                  <h2>Introduction to Digital Marketing</h2>
                  <p>
                  -Driving online visibility and engagement through strategic digital marketing initiatives tailored to your brand.<br/>
- Specializing in SEO, social media management, PPC advertising, content marketing, and more, we optimize your digital presence for maximum impact.
                  </p>
                </div>
                <div class="image-gallery col-md-6">
                  <img src={DigitalMarketingImage} alt="Digital Marketing" />
                </div>
              </div>
            </div>
  
            <div className="sectioononnn">
              <h1 style={{ textAlign: "center" }}>
                Why Choose Our Digital Marketing Solutions?
              </h1>
              <div>
              <div class="row justify-content-center sdfsfsf ">
                <div class=" col-lg-4 col-md-6 mb-4 ">
                  <div class="card custom-card">
                    <div class="card-body">
                      <h3 class="card-title">Enhanced Efficiency</h3>
                      <p class="card-text">
                      - Section highlighting the unique advantages of Yatayati's digital marketing services.                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                  <div class="card custom-card">
                    <div class="card-body">
                      <h3 class="card-title">Improved Security</h3>
                      <p class="card-text">
                      - Emphasize the expertise of Yatayati's marketing professionals in creating customized strategies tailored to each client's goals.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-4" >
                  <div class="card custom-card">
                    <div class="card-body">
                      <h3 class="card-title">Cost Optimization</h3>
                      <p class="card-text">
                      - Highlight Yatayati's focus on data-driven insights and continuous optimization for maximum ROI.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
  
            <div>
              <h2 class="text-center" style={{fontFamily:"anta"}}>Key Services Offered</h2>
              <div className="d-flex flex-row justify-content-center"style={{fontFamily:"anta"}}>
            <div >
            <div class="row sdfsfsf">
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color1 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg2}
                      alt="Search engine optimization (SEO)"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Search engine optimization (SEO)
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color2 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg3}
                      alt="Pay-per-click (PPC) advertising"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">Pay-per-click (PPC) advertising</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="card yt-card-color3 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg4}
                      alt="Social media marketing (SMM)"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Social media marketing (SMM)
                    </p>
                  </div>
                </div>
              </div>
              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color4 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg5}
                      alt=" Email marketing"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Email marketing
                    </p>
                  </div>
                </div>
              </div>

              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color5 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg6}
                      alt="Content marketing"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">
                    Content marketing
                    </p>
                  </div>
                </div>
              </div>
              <div class=" col-lg-4 col-md-6">
                <div class="card yt-card-color6 text-center yt-custom-card">
                  <div class="card-body">
                    <img
                      src={Imggg7}
                      alt="Web Analytics and Reporting"
                      class="card-img-top"
                    />
                    <p class="card-text mt-3">Web Analytics and Reporting</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
            </div>
       <br/>
            <div>
          <h2 class="text-center mb-4" style={{fontFamily:"anta"}}>Why Choose Yatayati for Digital Marketing?</h2>
          <div class="why-choose-yatayati-section d-flex justify-content-between row">
              <div className="col-lg-4 col-md-6 mb-4">
              <div class="value-proposition yt-section">
                  <h3>Value Proposition</h3>
                  <p>- Explanation of the value proposition of partnering with Yatayati for digital marketing needs.</p>
              </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
              <div class="team-expertise yt-section ">
                  <h3>Team Expertise</h3>
                  <p>- Mention the commitment to delivering measurable results and transparent reporting to track campaign performance.</p>
              </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4">
              <div class="cost-effective-solutions yt-section ">
                  <h3>Cost-Effective Solutions</h3>
                  <p>- Highlight Yatayati's focus on staying ahead of digital marketing trends and leveraging cutting-edge tools and technologies.</p>
              </div>
              </div>
          </div>
      </div>
          <div>
              <h1 style={{textAlign:"center", fontFamily:"anta"}}>Client Success Stories</h1>
          <div class="outerdiv">
              
              <div class="innerdiv">
              
              <div class="div1 eachdiv">
                  <div class="userdetails">
                  <div class="imgbox">
                      <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-daniel.jpg" alt=""/>
                  </div>
                  <div class="detbox">
                <p class="name">Daniel Clifford</p>
                <p class="designation">Digital Marketing Specialist</p>
            </div>
        </div>
        <div class="review">
            <h4>Transitioned my career with digital marketing</h4>
            <p>“ After years of working in a different industry, I decided to make a career shift into digital marketing. This bootcamp provided me with the skills and knowledge I needed to succeed in this field. The practical experience and guidance from industry experts were invaluable. Since completing the course, I've successfully transitioned into a role as a Digital Marketing Specialist and couldn't be happier with my decision. ”</p>
        </div>
              </div>
              
              <div class="div2 eachdiv">
                  <div class="userdetails">
                  <div class="imgbox">
                      <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jonathan.jpg" alt=""/>
                  </div>
                  <div class="detbox">
                <p class="name">Jonathan Walters</p>
                <p class="designation">Digital Marketing Manager</p>
            </div>
        </div>
        <div class="review">
            <h4>Received excellent support from the team</h4>
            <p>“ As someone new to digital marketing, I was unsure about my abilities. However, the supportive team at this bootcamp kept me motivated throughout the program. ”</p>
        </div>
              </div>
              
              <div class="div3 eachdiv">
                  <div class="userdetails">
                  <div class="imgbox">
                      <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-kira.jpg" alt=""/>
                  </div>
                  <div class="detbox">
                <p class="name dark">Kira Whittle</p>
                <p class="designation dark">Digital Marketing Strategist</p>
            </div>
        </div>
        <div class="review dark">
            <h4>Life-changing experience in digital marketing</h4>
            <p>“ This bootcamp was a turning point in my career. The comprehensive curriculum and hands-on projects helped me develop practical skills in digital marketing. The instructors were knowledgeable and provided valuable insights into industry trends. Now, I work as a Digital Marketing Strategist, and I'm grateful for the opportunity to learn and grow in this field. I now work in a role I truly enjoy and am thankful for the opportunity to pursue my passion.”</p>
        </div>
              </div>
              <div class="div4 eachdiv">
                  <div class="userdetails">
                  <div class="imgbox">
                      <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jeanette.jpg" alt=""/>
                  </div>
                  <div class="detbox">
                <p class="name dark">Jeanette Harmon</p>
                <p class="designation dark">Digital Marketing Coordinator</p>
            </div>
        </div>
        <div class="review dark">
            <h4>Grateful for the rewarding experience</h4>
            <p>“ Thank you for the wonderful experience! This bootcamp equipped me with the skills and knowledge needed to excel in the digital marketing field.  ”</p>
        </div>
              </div>
              <div class="div5 eachdiv">
                  <div class="userdetails">
                  <div class="imgbox">
                      <img src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-patrick.jpg" alt=""/>
                  </div>
                  <div class="detbox">
                <p class="name">Patrick Abrams</p>
                <p class="designation">Digital Marketing Analyst</p>
            </div>
        </div>
        <div class="review">
            <h4>Great teaching support from experienced professionals</h4>
            <p>“ The teaching support at this bootcamp was outstanding. The instructors, who were experienced professionals in the field, provided valuable insights and guidance throughout the program. Learning from their experiences made the learning process enjoyable and rewarding. I'm grateful for the genuine concern they showed for my progress and development. ”</p>
        </div>
              </div>
              </div>
          </div>
          </div>
          <div style={{textAlign:"center" }}>
            <div className="row  p-5" style={{fontFamily:"anta"}}>
                  <div className="ctsts d-flex flex-column justify-content-center col-md-6">
                      <h1>"Have questions or feedback? Reach out to us. We're here to help! "</h1>
                  </div>
                  <div className="col-md-6 ">
                  <button class="cta">
                     <Link to="/contact"><span style={{fontFamily:"anta"}}>Contact Us</span></Link>
                      <svg width="15px" height="10px" viewBox="0 0 13 10">
                          <path d="M1,5 L11,5"></path>
                          <polyline points="8 1 12 5 8 9"></polyline>
                      </svg>
                      </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
          <IoArrowBack style={{ transform: "rotate(90deg)" }} />
        </button>
        <Footer />
      </div>
    );
};
