
import { Link } from 'react-router-dom'
import yat from "../img/yat.jpg";
import React,{useEffect} from 'react';
import './home.css'
import { IoArrowBack } from 'react-icons/io5';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import { Navbarr } from '../components/navbarr';
import Footer from '../components/footer';


export const Servicesmain = () => {
    const phoneNumber = '+917799955921';

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
   
  return (
    <div>
        <div className='background-container'>
            <div>
             <Navbarr/>
            </div>
            <div className='contininin'>
            <div class="containererere">
                <div class="row justify-content-center">                  
                    <div class="col-lg-4 col-md-6">
                    <Link to="/servicessubpage">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center">
                                    <p>Employee Management</p>
                                </div>
                                <div class="card-back">
                                    <p>Technology stack<br/> WPF (.Net Framework), C#, and MySQL.</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>                                
                    <div class="col-lg-4 col-md-6">
                    <Link to="/servicessubpage1">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center " style={{textAlign:"center"}}>
                                    <p>Assessment Management</p>
                                </div>
                                <div class="card-back">
                                    <p>Technology stack<br/> React, Node.js & MySQL.</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>               
                    <div class="col-lg-4 col-md-6">
                        <Link to="/servicessubpage2">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center">
                                    <p>Learning Management</p>
                                </div>
                                <div class="card-back">
                                    <p>Technology stack<br/> React, Node.js & MySQL.</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6">
                          <Link to="/servicessubpage3">                     
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center">
                                    <p>Student Management</p>
                                </div>
                                <div class="card-back">
                                    <p>Technology stack<br/> React, Node.js & MySQL.</p>
                                </div>
                            </div>
                        </div>
                        </Link>   
                    </div>
                    <div class="col-lg-4 col-md-6">
                          <Link to="/servicessubpage4">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center">
                                    <p>Chit Funds</p>
                                </div>
                                <div class="card-back">
                                    <p>Technology stack<br/> React, Node.js & MySQL.</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-md-6">
                          <Link to="/servicessubpage5">
                        <div class="card">
                            <div class="card-inner">
                                <div class="card-front d-flex flex-column justify-content-center">
                                    <p>Web Development</p>
                                </div>
                                <div class="card-back">
                                    <p>Technology stack<br/> React, Node.js & MySQL.</p>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <button className="scroll-to-top-button" onClick={handleScrollToTop}>
                <IoArrowBack style={{ transform: 'rotate(90deg)' }} />
         </button>
         <Footer/>
    </div>
  )
}
